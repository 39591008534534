import { PATH_DEPOSIT_APPLICATION_JOINT_INVITE } from '@/constants/routes';
import Link from 'next/link';
import React from 'react';

function InvitedImage() {
  return (
    <svg viewBox="0 0 344 120" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_5451_58033)">
        <rect width="344" height="120" fill="#006982" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 0H344V21.0332V35.4239V35.4241H0V35.4239V21.0332V0ZM344 37.585H0V55.2755H344V37.585ZM0 60.3613H344V73.8504H0V60.3613ZM344 83.1377H0V92.4252H344V83.1377ZM0 105.914H344V111H0V105.914Z"
          fill="#00566B"
        />
        <circle cx="159.5" cy="45.5" r="54.5" fill="#0886A5" />
        <circle cx="100.5" cy="45.5" r="54.5" fill="#1EA1C1" />
        <path
          d="M220 61.5C220 46.8645 231.864 35 246.5 35C261.136 35 273 46.8645 273 61.5V92H220V61.5Z"
          fill="#2ADA62"
        />
        <path d="M226 29H220C193.49 29 172 50.4903 172 77V92H226V29Z" fill="#6EF499" />
        <path d="M133 29H139C165.51 29 187 50.4903 187 77V92H133V29Z" fill="#B4FDCB" />
        <path d="M110 64H111C126.464 64 139 76.536 139 92H110V64Z" fill="#43EA79" />
        <path d="M80 92H294C294 112.435 277.435 129 257 129H117C96.5655 129 80 112.435 80 92Z" fill="#39B8D5" />
      </g>
      <defs>
        <clipPath id="clip0_5451_58033">
          <rect width="344" height="120" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export function OpenJointInvitation() {
  return (
    <div className="bg-white overflow-hidden flex flex-col rounded-lg col-span-12 lg:col-span-4">
      <InvitedImage />
      <div className="p-6 flex flex-col flex-1 justify-between">
        <div>
          <p className="text-sm font-normal text-gray-500">You’re invited!</p>
          <h3 className="font-medium text-lg text-gray-950 mb-2 leading-6">Joint Deposit Account</h3>
          <p className="font-normal text-base text-gray-900 mb-4">
            Open a joint account with someone else, manage your finances and start earning up to 4.70% APY<sup>1</sup>{' '}
            on your balance together.
          </p>
        </div>
        <Link
          href={{
            pathname: PATH_DEPOSIT_APPLICATION_JOINT_INVITE,
          }}
          className="block w-full text-center text-white rounded-lg py-2.5 text-base bg-blue-800 hover:opacity-90"
        >
          Apply Now
        </Link>
      </div>
    </div>
  );
}
