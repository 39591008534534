import { DepositAccount, useGetDepositAccountsQuery, useGetMonthlyCashflowByAccountIdQuery } from '@/generated/graphql';
import { DateTime } from 'luxon';
import * as React from 'react';

function sortByBalance(a: DepositAccount, b: DepositAccount) {
  return a.depositAccountBalances?.availableBalanceInCents >= b.depositAccountBalances?.availableBalanceInCents
    ? -1
    : 1;
}

function getCurrentDate() {
  return DateTime.now().toFormat('yyyy-MM-dd');
}

function getCashflowStartDate() {
  return DateTime.now().minus({ months: 5 }).set({ day: 1 }).toFormat('yyyy-MM-dd');
}

export function useCashflowSummary(props: { isEnabled: boolean }) {
  const [selectedAccountId, setSelectedAccountId] = React.useState<string | null>(null);

  const getMonthlyCashflowByAccountIdQuery = useGetMonthlyCashflowByAccountIdQuery(
    {
      depositAccountId: selectedAccountId,
      endDate: getCurrentDate(),
      startDate: getCashflowStartDate(),
    },
    {
      enabled: props.isEnabled && !!selectedAccountId,
    },
  );
  const getAllAccountsQuery = useGetDepositAccountsQuery();

  const accounts = getAllAccountsQuery.data?.depositAccounts || [];
  const monthlyCashflow = getMonthlyCashflowByAccountIdQuery.data?.monthlyCashFlow || [];

  function onChangeAccount(value: string) {
    setSelectedAccountId(value);
  }

  React.useEffect(() => {
    if (!selectedAccountId && accounts.length !== 0) {
      const defaultAccount = [...accounts].sort(sortByBalance)[0];
      setSelectedAccountId(defaultAccount.id);
    }
  }, [selectedAccountId, accounts.length]);

  return {
    isLoading: !getMonthlyCashflowByAccountIdQuery.isError && !getMonthlyCashflowByAccountIdQuery.data,
    isError: getMonthlyCashflowByAccountIdQuery.isError || getAllAccountsQuery.isError,
    isEmpty:
      !getMonthlyCashflowByAccountIdQuery.isLoading && !getAllAccountsQuery.isLoading && monthlyCashflow.length === 0,
    monthlyCashflow,
    accounts,
    selectedAccountId,
    onChangeAccount,
    noAccounts: getAllAccountsQuery.isFetched && accounts.length === 0,
  };
}
