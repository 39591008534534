import { fromCents } from '@/helpers/currency';
import { CurrencyCompactFormatter } from '@/i18n/numbers';

export function Legend(props: {
  maxDeposits: number;
  maxWithdrawals: number;
  depositsHeight: number;
  withdrawalsHeight: number;
}) {
  return (
    <div className="w-[50px] relative pl-6">
      {props.depositsHeight > 25 && props.maxDeposits !== 0 && (
        <span className="text-sm text-gray-500 absolute top-0">
          {CurrencyCompactFormatter.format(fromCents(props.maxDeposits))}
        </span>
      )}
      <span
        className="text-sm text-gray-500 absolute -translate-y-1/2"
        style={{ top: `${(props.maxDeposits / (props.maxDeposits + props.maxWithdrawals)) * 100}%` }}
      >
        $0
      </span>
      {props.withdrawalsHeight > 25 && props.maxWithdrawals !== 0 && (
        <span className="text-sm text-gray-500 absolute bottom-0">
          -{CurrencyCompactFormatter.format(fromCents(props.maxWithdrawals))}
        </span>
      )}
    </div>
  );
}
