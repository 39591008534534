import { Dropdown } from '@/components/form/components/form-inputs/dropdown';
import { InfoButtonToolTip } from '@/components/info-button-tool-tip';
import { DepositAccount, DepositAccountType } from '@/generated/graphql';

function mapAccountOption(account: DepositAccount) {
  return {
    label: `${account.accountType === DepositAccountType.Individual ? 'Deposit Account' : 'Joint Deposit Account'} ${
      account.depositAccountNumber.lastFour
    }`,
    value: account.id,
  };
}

export function CashflowSummaryHeader(props: {
  text: string;
  withAccountsDropdown?: boolean;
  accounts?: DepositAccount[];
  selectedAccountId?: string | null;
  onChangeAccount?: (value: string) => void;
}) {
  const { withAccountsDropdown = false, accounts = [] } = props;

  function onChange(event: { value: string | number }) {
    props.onChangeAccount(event.value as string);
  }

  const options = accounts.map(mapAccountOption);
  const value = options.find((option) => option.value === props.selectedAccountId);

  return (
    <div className="flex justify-between flex-wrap">
      <div className="mb-2">
        <div className="flex items-center no-wrap">
          <p className="text-lg font-medium mr-1.5">Cash flow summary</p>
          <InfoButtonToolTip className="mt-1.5 relative z-20">
            <div className="bg-gray-700 rounded p-4">
              <p className="text-white text-sm">The calculation of cash flow is as follows:</p>
              <ul className="list-disc ml-3.5">
                <li className="text-white text-sm">
                  Total funds into your deposit account(s) during the month (money in) minus total funds out of your
                  deposit account(s) (money out) during the month is your monthly cash flow.
                </li>
                <li className="text-white text-sm">
                  HMBradley Credit Card payments from HMBradley Deposit Account(s) are excluded from money out during
                  the month.
                </li>
              </ul>
            </div>
          </InfoButtonToolTip>
        </div>
        <p className="text-gray-600 text-sm font-normal mt-0.5">{props.text}</p>
      </div>
      {withAccountsDropdown && (
        <div className="w-60 mb-2">
          <Dropdown
            valueContainerStyles={{
              paddingTop: '0.6rem',
              paddingBottom: '0.6rem',
              paddingLeft: '0.75rem',
              paddingRight: '0.75rem',
            }}
            value={value}
            onChange={onChange}
            options={props.accounts.map(mapAccountOption)}
          />
        </div>
      )}
    </div>
  );
}
