import { Dialog } from '@headlessui/react';
import cx from 'clsx';
import React from 'react';

import { FULLSTORY_UNMASK } from '@/constants';
import { PATH_ACCOUNT_DEPOSIT_DETAILS } from '@/constants/routes';
import { DepositAccountType, JointAccountInvite, useGetDepositAccountsQuery } from '@/generated/graphql';
import { routeFor } from '@/helpers/router';
import { acceptJointInviteStorage } from '@/storages/local';
import { httpClient } from '@/transports/http';
import { useGetRegistrarV1Person } from '@core/mainframe-react-query';
import Link from 'next/link';

function CongratulationsImage() {
  return (
    <svg className="w-full" viewBox="0 0 500 220" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_5312_56185)">
        <path d="M0 8.00001C0 3.58173 3.58172 0 8 0H492C496.418 0 500 3.58172 500 8V220H0V8.00001Z" fill="#006982" />
        <rect x="-7" width="160.602" height="221" fill="#29353B" />
        <g opacity="0.6">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M153.604 1.37134H516.332V36.4857V60.5102V60.5109H153.604V60.5102V36.4857V1.37134ZM516.332 64.1174H153.604V93.6511H516.332V64.1174ZM153.604 102.142H516.332V124.661H153.604V102.142ZM516.332 140.167H153.604V155.672H516.332V140.167ZM153.604 178.191H516.332V186.682H153.604V178.191Z"
            fill="#00566B"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M-0.478516 1.37134H153.604V36.4857V60.5102V60.5109H-0.478516V60.5102V36.4857V1.37134ZM153.604 64.1174H-0.478516V93.6511H153.604V64.1174ZM-0.478516 102.142H153.604V124.661H-0.478516V102.142ZM153.604 140.167H-0.478516V155.672H153.604V140.167ZM-0.478516 178.191H153.604V186.682H-0.478516V178.191Z"
            fill="#1A2428"
          />
        </g>
        <g filter="url(#filter0_d_5312_56185)">
          <path
            d="M517.36 1.37134C488.518 1.37134 459.958 7.05217 433.312 18.0895C406.666 29.1268 382.454 45.3044 362.06 65.6987C341.665 86.093 325.488 110.305 314.451 136.951C303.413 163.597 297.732 192.157 297.732 220.999L517.36 220.999V1.37134Z"
            fill="#0886A5"
          />
          <path
            d="M517.36 1.37134C488.518 1.37134 459.958 7.05217 433.312 18.0895C406.666 29.1268 382.454 45.3044 362.06 65.6987C341.665 86.093 325.488 110.305 314.451 136.951C303.413 163.597 297.732 192.157 297.732 220.999L517.36 220.999V1.37134Z"
            fill="url(#paint0_radial_5312_56185)"
            fillOpacity="0.3"
          />
          <path
            d="M517.36 1.37134C488.518 1.37134 459.958 7.05217 433.312 18.0895C406.666 29.1268 382.454 45.3044 362.06 65.6987C341.665 86.093 325.488 110.305 314.451 136.951C303.413 163.597 297.732 192.157 297.732 220.999L517.36 220.999V1.37134Z"
            fill="url(#paint1_linear_5312_56185)"
            fillOpacity="0.4"
          />
        </g>
        <path
          d="M332.748 176.026C333.491 175.827 334.172 176.507 333.973 177.251L330.597 189.85C330.397 190.594 329.468 190.843 328.924 190.298L319.7 181.075C319.156 180.531 319.405 179.601 320.149 179.402L332.748 176.026Z"
          fill="#43EA79"
        />
        <path
          d="M316.275 27.7776C317.019 27.5784 317.699 28.2588 317.5 29.0024L314.124 41.6016C313.925 42.3451 312.995 42.5942 312.451 42.0499L303.228 32.8266C302.683 32.2823 302.932 31.3528 303.676 31.1536L316.275 27.7776Z"
          fill="#FFD166"
        />
        <circle cx="180.443" cy="210.78" r="7.6246" fill="#FFD166" />
        <circle cx="17.0953" cy="66.6495" r="6.6246" stroke="#8998A1" strokeWidth="2" />
        <circle cx="391.834" cy="114.693" r="6.6246" stroke="#B5EEF4" strokeWidth="2" />
        <rect
          x="128.893"
          y="13.188"
          width="12.7077"
          height="12.7077"
          rx="1"
          transform="rotate(-10 128.893 13.188)"
          fill="#43EA79"
        />
        <rect
          x="52.7773"
          y="175.702"
          width="12.7077"
          height="12.7077"
          rx="1"
          transform="rotate(54.6249 52.7773 175.702)"
          fill="#96E6F0"
        />
        <rect opacity="0.1" x="9.4707" y="1.37268" width="288.261" height="219.627" rx="109.814" fill="#1FC052" />
        <rect opacity="0.3" x="42.4141" y="34.3168" width="222.373" height="153.739" rx="76.8696" fill="#2ADA62" />
        <g filter="url(#filter1_d_5312_56185)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M153.601 83.7642C145.552 73.7042 133.171 67.2609 119.285 67.2609C95.0255 67.2609 75.3594 86.927 75.3594 111.186C75.3594 135.446 95.0255 155.112 119.285 155.112C133.171 155.112 145.552 148.668 153.601 138.608C161.651 148.668 174.032 155.112 187.918 155.112C212.177 155.112 231.843 135.446 231.843 111.186C231.843 86.927 212.177 67.2609 187.918 67.2609C174.032 67.2609 161.651 73.7042 153.601 83.7642Z"
            fill="#6EF499"
          />
        </g>
        <circle cx="119.285" cy="111.186" r="43.9255" transform="rotate(-90 119.285 111.186)" fill="#2ADA62" />
        <g filter="url(#filter2_i_5312_56185)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M153.601 138.608C147.588 131.093 143.992 121.56 143.992 111.186C143.992 100.813 147.588 91.2794 153.601 83.7642C159.614 91.2794 163.21 100.813 163.21 111.186C163.21 121.56 159.614 131.093 153.601 138.608Z"
            fill="#43EA79"
          />
        </g>
        <circle cx="187.918" cy="111.186" r="21.9627" transform="rotate(-90 187.918 111.186)" fill="white" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M187.918 133.149C200.047 133.149 209.881 123.316 209.881 111.186C209.881 99.0567 200.047 89.2236 187.918 89.2236C175.788 89.2236 165.955 99.0567 165.955 111.186C165.955 123.316 175.788 133.149 187.918 133.149ZM198.095 107.637C199.167 106.565 199.167 104.827 198.095 103.754C197.023 102.682 195.285 102.682 194.213 103.754L185.172 112.795L181.623 109.245C180.551 108.173 178.813 108.173 177.741 109.245C176.668 110.317 176.668 112.055 177.741 113.128L183.231 118.618C184.303 119.69 186.042 119.69 187.114 118.618L198.095 107.637Z"
          fill="#6EF499"
        />
        <circle cx="119.285" cy="111.186" r="21.9627" transform="rotate(-90 119.285 111.186)" fill="white" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M119.285 133.149C131.415 133.149 141.248 123.316 141.248 111.186C141.248 99.0567 131.415 89.2236 119.285 89.2236C107.155 89.2236 97.3223 99.0567 97.3223 111.186C97.3223 123.316 107.155 133.149 119.285 133.149ZM129.462 107.637C130.534 106.565 130.534 104.827 129.462 103.754C128.39 102.682 126.652 102.682 125.58 103.754L116.54 112.795L112.99 109.245C111.918 108.173 110.18 108.173 109.108 109.245C108.036 110.317 108.036 112.055 109.108 113.128L114.598 118.618C115.671 119.69 117.409 119.69 118.481 118.618L129.462 107.637Z"
          fill="#2ADA62"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_5312_56185"
          x="273.732"
          y="-18.6287"
          width="267.627"
          height="267.627"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="12" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.411765 0 0 0 0 0.509804 0 0 0 1 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5312_56185" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_5312_56185" result="shape" />
        </filter>
        <filter
          id="filter1_d_5312_56185"
          x="55.3594"
          y="47.2609"
          width="196.484"
          height="127.851"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="10" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.121569 0 0 0 0 0.752941 0 0 0 0 0.321569 0 0 0 0.4 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5312_56185" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_5312_56185" result="shape" />
        </filter>
        <filter
          id="filter2_i_5312_56185"
          x="143.992"
          y="83.7642"
          width="19.2188"
          height="54.8444"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="2.5" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.298039 0 0 0 0 0.85098 0 0 0 0 0.392157 0 0 0 0.24 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_5312_56185" />
        </filter>
        <radialGradient
          id="paint0_radial_5312_56185"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(463.833 189.325) rotate(-149.848) scale(155.901)"
        >
          <stop stopColor="#1EA1C1" />
          <stop offset="1" stopColor="#56CBE3" />
        </radialGradient>
        <linearGradient
          id="paint1_linear_5312_56185"
          x1="407.546"
          y1="1.37134"
          x2="407.546"
          y2="301.529"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#006982" />
          <stop offset="1" stopColor="#56CBE3" />
        </linearGradient>
        <clipPath id="clip0_5312_56185">
          <path d="M0 8.00001C0 3.58173 3.58172 0 8 0H492C496.418 0 500 3.58172 500 8V220H0V8.00001Z" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

function isJointAccount(account: { accountType?: DepositAccountType | null } | null) {
  return account?.accountType === DepositAccountType.Joint;
}

export function AcceptedJointInviteModal() {
  const [isOpen, setOpen] = React.useState(false);
  const [accountId, setAccountId] = React.useState<string | undefined>();
  const [acceptedJointInvite, setAcceptedJointInvite] = React.useState<JointAccountInvite | undefined>();

  const personQuery = useGetRegistrarV1Person(httpClient, {});
  const depositAccountsQuery = useGetDepositAccountsQuery();

  const personId = personQuery.data?.id;
  const depositAccounts = depositAccountsQuery.data?.depositAccounts || [];
  const jointAccounts = depositAccounts.filter(isJointAccount);

  const storageData = acceptJointInviteStorage?.get() || '';

  React.useEffect(() => {
    if (jointAccounts.length > 0 && !!personId) {
      const convertedJointAccount = jointAccounts.find(
        (account) =>
          account.pendingJointAccountInvite?.inviterId === personId && !storageData.includes(account.id || ''),
      );

      if (!!convertedJointAccount) {
        setOpen(true);
        setAccountId(convertedJointAccount.id);
        setAcceptedJointInvite(convertedJointAccount.pendingJointAccountInvite);
        acceptJointInviteStorage?.set(storageData + convertedJointAccount.id);
      }
    }
  }, [jointAccounts.length, personId]);

  function onClose() {
    setAcceptedJointInvite(undefined);
    setAccountId(undefined);
    setOpen(false);
  }

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      className={cx('fixed inset-0 flex items-center justify-center z-4000', FULLSTORY_UNMASK)}
    >
      <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-50" />
      <div className="bg-white z-10 w-4/5 md:w-1/2 lg:w-1/3 max-w-[500px] rounded-lg flex flex-col">
        <CongratulationsImage />
        <div className="p-5">
          <p className="text-gray-950 mb-2 font-normal text-md">Congratulations!</p>
          <p className="text-gray-950 font-sans-medium text-md leading-6">
            {acceptedJointInvite?.inviteeFirstName} has joined your deposit account!
          </p>
          <div className="flex w-full sm:flex-row flex-col-reverse items-center sm:justify-end mt-6">
            <button
              onClick={onClose}
              className="px-4 text-center text-base text-gray-800 py-2.5 border-2 mt-2 sm:mt-0 border-gray-200 rounded-lg sm:mr-3 w-full sm:w-fit"
            >
              Cancel
            </button>
            <Link
              className="sm:w-fit w-full text-center text-base font-medium px-5 rounded-lg text-gray-100 py-2.5 bg-blue"
              href={{
                pathname: routeFor(PATH_ACCOUNT_DEPOSIT_DETAILS, {
                  path: {
                    depositAccountId: accountId,
                  },
                }),
              }}
            >
              See your joint account details
            </Link>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
