export enum OfferPromoInterval {
  Monthly = 'MONTHLY',
}

export type OfferPromo = {
  interval: OfferPromoInterval;
  value: string;
  interestRate: string;
};

export type Offer = {
  creditLine: number;
  expiresOn: string;
  offerID: string;
  interestRate: string;
  promo?: OfferPromo;
};
