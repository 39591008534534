export function ArrowIcon() {
  return (
    <svg
      className="min-w-[15px]"
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.58325 3.41669L9.66659 7.50002L5.58325 11.5834"
        stroke="#8998A1"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function DepositAccountCardInfoIcon() {
  return (
    <svg
      className="min-w-[18px]"
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.66667 11.8024H9V9.13574H8.33333M9 6.46908H9.00667M15 9.13574C15 9.92367 14.8448 10.7039 14.5433 11.4318C14.2417 12.1598 13.7998 12.8212 13.2426 13.3784C12.6855 13.9355 12.0241 14.3775 11.2961 14.679C10.5681 14.9805 9.78793 15.1357 9 15.1357C8.21207 15.1357 7.43185 14.9805 6.7039 14.679C5.97595 14.3775 5.31451 13.9355 4.75736 13.3784C4.20021 12.8212 3.75825 12.1598 3.45672 11.4318C3.15519 10.7039 3 9.92367 3 9.13574C3 7.54444 3.63214 6.01832 4.75736 4.8931C5.88258 3.76788 7.4087 3.13574 9 3.13574C10.5913 3.13574 12.1174 3.76788 13.2426 4.8931C14.3679 6.01832 15 7.54444 15 9.13574Z"
        stroke="#67767F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function CreditCardAutopayIcon() {
  return (
    <svg
      className="min-w-[13px]"
      width="13"
      height="12"
      viewBox="0 0 13 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1.83325 6.5L1.08325 5.75L2.83325 4L4.58325 5.75L3.83325 6.5L2.83325 5.5L1.83325 6.5Z" fill="#1F1F1F" />
      <path d="M11.5833 6.25L10.8333 5.5L9.83325 6.5L8.83325 5.5L8.08325 6.25L9.83325 8L11.5833 6.25Z" fill="#1F1F1F" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.45457 8.12132C7.91168 8.66421 7.16168 9 6.33325 9C4.6764 9 3.33325 7.65685 3.33325 6C3.33325 5.69691 3.3782 5.40432 3.46179 5.12854L2.68676 4.3535C2.45967 4.85565 2.33325 5.41306 2.33325 6C2.33325 8.20914 4.12411 10 6.33325 10C7.43782 10 8.43782 9.55229 9.16168 8.82843L8.45457 8.12132ZM4.21193 3.87868C4.75482 3.33579 5.50482 3 6.33325 3C7.99011 3 9.33325 4.34315 9.33325 6C9.33325 6.30309 9.28831 6.59567 9.20472 6.87146L9.97975 7.6465C10.2068 7.14435 10.3333 6.58694 10.3333 6C10.3333 3.79086 8.54239 2 6.33325 2C5.22868 2 4.22868 2.44772 3.50482 3.17157L4.21193 3.87868Z"
        fill="#1F1F1F"
      />
    </svg>
  );
}

export function ErrorIcon() {
  return (
    <svg
      className="min-w-[20px]"
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 6.56006V10.5601M10 14.5601H10.01M19 10.5601C19 11.742 18.7672 12.9123 18.3149 14.0042C17.8626 15.0961 17.1997 16.0883 16.364 16.924C15.5282 17.7597 14.5361 18.4227 13.4442 18.875C12.3522 19.3273 11.1819 19.5601 10 19.5601C8.8181 19.5601 7.64778 19.3273 6.55585 18.875C5.46392 18.4227 4.47177 17.7597 3.63604 16.924C2.80031 16.0883 2.13738 15.0961 1.68508 14.0042C1.23279 12.9123 1 11.742 1 10.5601C1 8.17311 1.94821 5.88392 3.63604 4.1961C5.32387 2.50827 7.61305 1.56006 10 1.56006C12.3869 1.56006 14.6761 2.50827 16.364 4.1961C18.0518 5.88392 19 8.17311 19 10.5601Z"
        stroke="#97310C"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
