import { CLOSED_OVERVIEW_BANNERS_STORAGE_KEY } from '@/constants/app';
import { OverviewBanners } from '@/routes/account/routes/accounts-overview/components/banner/helpers/types';
import { getLocalStorageItem, setLocalStorageItem } from '@/storages/local';

export function getClosedBanners() {
  return getLocalStorageItem<string[]>(CLOSED_OVERVIEW_BANNERS_STORAGE_KEY) ?? [];
}

export function addClosedBanner(banner: OverviewBanners) {
  const closedBanners = getLocalStorageItem<string[]>(CLOSED_OVERVIEW_BANNERS_STORAGE_KEY);
  if (!closedBanners) {
    setLocalStorageItem(CLOSED_OVERVIEW_BANNERS_STORAGE_KEY, [banner]);
  } else {
    const closedBanner = closedBanners.find((closedBanner) => closedBanner === banner);
    if (!closedBanner) {
      setLocalStorageItem(CLOSED_OVERVIEW_BANNERS_STORAGE_KEY, [...closedBanners, banner]);
    }
  }
}
