import { PATH_ACCOUNT_ACTIVATION } from '@/constants/routes';
import { DepositAccount, DepositAccountType } from '@/generated/graphql';
import { routeFor } from '@/helpers/router';
import { XMarkIcon } from '@heroicons/react/24/outline';
import Link from 'next/link';
import React from 'react';
import { useSwiper } from 'swiper/react';

function ItemImage() {
  return (
    <>
      <div className="hidden md:block">
        <svg
          className="min-w-[120px]"
          width="120"
          height="120"
          viewBox="0 0 120 120"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_47_1587)">
            <rect width="120" height="120" rx="8" fill="#D3F5F8" />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M88.2439 88.244V24H-7.01434L56.122 24V24.0098C38.8516 24.4334 24.9816 38.5686 24.9816 55.9439C24.9816 73.5861 10.6248 88.244 -7.01434 88.244H88.2439Z"
              fill="#2ADA62"
            />
            <rect
              x="-22.9024"
              y="120"
              width="31.7561"
              height="111.146"
              rx="15.878"
              transform="rotate(-90 -22.9024 120)"
              fill="#FDC03F"
            />
            <rect x="88.2439" y="-22.9023" width="31.7561" height="112.47" rx="15.878" fill="#96FAB5" />
            <path
              d="M88.2439 120H120V72.3659C120 63.5966 112.891 56.4878 104.122 56.4878C95.3527 56.4878 88.2439 63.5966 88.2439 72.3659V120Z"
              fill="#2ADA62"
            />
            <circle cx="104.122" cy="72.3658" r="15.878" fill="#F5F6F8" />
            <rect x="56.4878" y="24.7317" width="31.7561" height="79.3902" fill="#1A2428" />
            <circle cx="72.3658" cy="104.12" r="15.878" fill="#8998A1" />
            <circle cx="72.3658" cy="24.7318" r="15.878" fill="#FDC03F" />
          </g>
          <defs>
            <clipPath id="clip0_47_1587">
              <rect width="120" height="120" rx="8" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </div>
      <div className="block md:hidden">
        <svg width="72" height="174" viewBox="0 0 72 174" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_49_4532)">
            <rect width="72" height="174" rx="8" fill="#D3F5F8" />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M79.2439 142.146V77.9023H-16.0144L47.1219 77.9024V77.9121C29.8516 78.3357 15.9816 92.4709 15.9816 109.846C15.9816 127.488 1.62478 142.146 -16.0144 142.146H79.2439Z"
              fill="#2ADA62"
            />
            <rect
              x="-31.9025"
              y="173.902"
              width="31.7561"
              height="111.146"
              rx="15.878"
              transform="rotate(-90 -31.9025 173.902)"
              fill="#FDC03F"
            />
            <rect x="47.4878" y="78.634" width="31.7561" height="79.3902" fill="#1A2428" />
            <circle cx="63.3658" cy="158.022" r="15.878" fill="#8998A1" />
            <circle cx="63.3658" cy="78.6342" r="15.878" fill="#FDC03F" />
          </g>
          <defs>
            <clipPath id="clip0_49_4532">
              <rect width="72" height="174" rx="8" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </div>
    </>
  );
}

export function AccountActivationItem(props: { onClose: () => void; accounts: DepositAccount[] }) {
  const swiper = useSwiper();

  function onClose() {
    swiper.removeSlide(swiper.activeIndex);
    props.onClose();
  }

  return (
    <div className="rounded-lg bg-white px-4 md:px-6 py-5 flex items-center justify-between h-full">
      <div className="flex items-center w-full">
        <ItemImage />
        <div className="flex-1 ml-4 md:ml-6">
          <div className="flex items-center justify-between">
            <p className="text-md text-gray-950 leading-6 md:mb-0 mb-0.5">Automate your savings goals</p>
            <div
              role="button"
              onClick={onClose}
              className="cursor-pointer rounded-full p-2 -mr-1 -mt-1 flex items-center justify-center text-gray-950 hover:bg-gray-50 duration-200"
            >
              <XMarkIcon width={20} height={20} />
            </div>
          </div>
          <p className="text-base text-gray-950 font-normal">
            The fastest way to wealth is consistency. Create a savings goal, then use Routines to set it and forget it.
          </p>
          <div className="flex flex-col sm:flex-row flex-wrap">
            {props.accounts.map((account) => (
              <Link
                className="mt-3"
                key={`account-activation-${account.id}`}
                href={{
                  pathname: routeFor(PATH_ACCOUNT_ACTIVATION, {
                    path: {
                      depositAccountId: account.id,
                    },
                  }),
                }}
              >
                <div className="rounded-lg py-2 px-6 w-fit border-gray-200 border-1 mr-2 mb-2 sm:mb-0 flex items-center">
                  <p className="text-gray-950 text-base whitespace-nowrap">
                    {account.accountType === DepositAccountType.Individual ? 'Deposit' : 'Joint deposit'} account
                  </p>
                  <p className="text-gray-950 text-base whitespace-nowrap hidden md:block ml-1">
                    •••• {account.depositAccountNumber.lastFour}
                  </p>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
