import { HOW_CASHFLOW_IS_CALCULATED_FAQ, URL_HMB_TO_APYS_NYCB, URL_HMB_TO_NYCB_PROMO } from '@/constants/app';

export function Footnotes() {
  return (
    <>
      <p>
        <sup>1</sup>APY means Annual Percentage Yield. Terms and conditions apply; see{' '}
        <a href={URL_HMB_TO_APYS_NYCB} className="underline" target="_blank" rel="noreferrer">
          Deposit Account Current APYs
        </a>{' '}
        and{' '}
        <a href={URL_HMB_TO_NYCB_PROMO} className="underline" target="_blank" rel="noreferrer">
          APY Promotion
        </a>{' '}
        for details.
      </p>
      <p>
        <sup>2</sup>See{' '}
        <a href={HOW_CASHFLOW_IS_CALCULATED_FAQ} className="underline" target="_blank" rel="noreferrer">
          how cash flow is calculated.
        </a>{' '}
      </p>
    </>
  );
}
