import { useFeatureWithUserStrategies } from '@/components/feature-flag/hooks/use-feature-with-user-strategies';
import { FEATURE_CASHFLOW } from '@/constants/features';
import { CashflowSummaryChart } from '@/routes/account/routes/home/components/cashflow-summary/components/cashflow-summary-chart';
import { CashflowSummaryEmpty } from '@/routes/account/routes/home/components/cashflow-summary/components/cashflow-summary-empty';
import { CashflowSummaryError } from '@/routes/account/routes/home/components/cashflow-summary/components/cashflow-summary-error';
import { CashflowSummarySkeleton } from '@/routes/account/routes/home/components/cashflow-summary/components/cashflow-summary-skeleton';
import { useCashflowSummary } from '@/routes/account/routes/home/components/cashflow-summary/hooks';
import React from 'react';

function CashflowLayout(props: { children: React.ReactNode }) {
  return <div className="lg:col-span-8 col-span-12 p-6 pb-0 bg-white rounded-lg">{props.children}</div>;
}

export function CashflowSummary() {
  const featureFlag = useFeatureWithUserStrategies(FEATURE_CASHFLOW);
  const controller = useCashflowSummary({ isEnabled: featureFlag.isEnabled });

  if (controller.noAccounts || !featureFlag.isEnabled) {
    return null;
  }

  if (controller.isLoading) {
    return (
      <CashflowLayout>
        <CashflowSummarySkeleton />
      </CashflowLayout>
    );
  }

  if (controller.isError) {
    return (
      <CashflowLayout>
        <CashflowSummaryError />
      </CashflowLayout>
    );
  }

  if (controller.isEmpty) {
    return (
      <CashflowLayout>
        <CashflowSummaryEmpty
          selectedAccountId={controller.selectedAccountId}
          onChangeAccount={controller.onChangeAccount}
          accounts={controller.accounts}
        />
      </CashflowLayout>
    );
  }

  return (
    <CashflowLayout>
      <CashflowSummaryChart
        selectedAccountId={controller.selectedAccountId}
        onChangeAccount={controller.onChangeAccount}
        accounts={controller.accounts}
        monthlyCashflow={controller.monthlyCashflow}
      />
    </CashflowLayout>
  );
}
