import { URL_HMB_TO_A_NEW_CHAPTER } from '@/constants/app';
import { useSwitchToNycb } from '@/hooks/use-nycb-active-accounts';

import { CreditCardIcon, CurrencyDollarIcon, QuestionMarkCircleIcon } from '@heroicons/react/24/outline';

export function GettingStarted() {
  const controller = useSwitchToNycb();

  if (controller.isLoading) {
    return null;
  }

  if (controller.showClosedHatchContent) {
    return <HaveQuestions />;
  }

  return null;
}

function HaveQuestions() {
  return (
    <div className="flex flex-col gap-6 pt-4">
      <div className="flex flex-col gap-1">
        <h2 className="font-medium text-lg">Your deposit account(s) with Hatch Bank is now closed.</h2>
        <p className="font-normal text-md">Have questions? We answer them below.</p>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
        <QuestionCard
          title="Where are my funds?"
          description="If you did not transfer your funds to an external account by October 31, 2022, we will mail a check for your remaining balance, over $1.00, to your address on file."
          icon={CurrencyDollarIcon}
        />
        <QuestionCard
          title="Why did my account close?"
          description={
            <p>
              HMBradley has opened a new deposit program with its new partner bank New York Community Bank, a division
              of Flagstar Bank, N.A. (NYCB). To support this change, all deposit accounts at Hatch Bank were closed on
              October 31, 2022. Read our blog{' '}
              <a href={URL_HMB_TO_A_NEW_CHAPTER} className="cursor-pointer text-blue" target="_blank" rel="noreferrer">
                here
              </a>{' '}
              for details.
            </p>
          }
          icon={QuestionMarkCircleIcon}
        />
        <QuestionCard
          title="Can I still use my credit card?"
          description="If you have an HMBradley Credit Card, you can still use it without any changes. All future payments must be made from an externally linked account."
          icon={CreditCardIcon}
        />
      </div>
      <hr className="border-black-lightest" />
      <p> Accounts </p>
    </div>
  );
}

function QuestionCard(props: { title: string; description: string | JSX.Element; icon: React.ElementType }) {
  return (
    <div className="flex flex-col gap-3">
      <div className="flex gap-3 items-center">
        <div className="rounded-full bg-purple-50 p-2 h-fit">
          <props.icon className=" text-purple-800 h-6 w-6" />
        </div>
        <h3 className="text-md font-medium">{props.title}</h3>
      </div>
      <p className="font-normal text-md text-gray-900">{props.description}</p>
    </div>
  );
}
