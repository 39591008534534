import { PATH_DEPOSIT_APPLICATION_JOINT_ACCEPT } from '@/constants/routes';
import { JointAccountInvite } from '@/generated/graphql';
import Link from 'next/link';
import React from 'react';

function InvitedImage() {
  return (
    <svg viewBox="0 0 344 120" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_5451_58132)">
        <rect width="344" height="120" fill="#006982" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-7 0H351V21.0332V35.4239V35.4241H-7V35.4239V21.0332V0ZM351 37.585H-7V55.2755H351V37.585ZM-7 60.3613H351V73.8504H-7V60.3613ZM351 83.1377H-7V92.4252H351V83.1377ZM-7 105.914H351V111H-7V105.914Z"
          fill="#00566B"
        />
        <path
          d="M110 62.5625C110 59.4342 111.464 56.4863 113.956 54.5956L161.425 18.585C164.998 15.8742 169.939 15.8742 173.512 18.585L220.981 54.5956C223.473 56.4863 224.937 59.4343 224.937 62.5625V124C224.937 129.523 220.46 134 214.937 134H120C114.477 134 110 129.523 110 124V62.5625Z"
          fill="#39B8D5"
        />
        <rect x="127.836" y="29.1924" width="79.2669" height="95.7808" rx="10" fill="#F5F6F8" />
        <path
          d="M110 59.6799C110 58.3665 110.61 57.1277 111.651 56.327C113.201 55.1347 115.366 55.1602 116.888 56.3887L161.143 92.1224C164.828 95.098 170.095 95.0801 173.76 92.0795L215.763 57.6891C218.422 55.5125 222.235 55.4741 224.937 57.5969V124C224.937 129.523 220.46 134 214.937 134H120C114.477 134 110 129.523 110 124V59.6799Z"
          fill="#96E6F0"
        />
        <ellipse
          cx="215.314"
          cy="80.3145"
          rx="26.3144"
          ry="26.3144"
          transform="rotate(-90 215.314 80.3145)"
          fill="#FDC03F"
        />
        <path
          d="M217.478 72.7308V77.8461C217.478 78.3983 217.926 78.8461 218.478 78.8461H224.431C225.281 78.8461 225.743 79.8388 225.197 80.4894L216.644 90.6673C216.044 91.3818 214.879 90.9573 214.879 90.024V84.9088C214.879 84.3565 214.431 83.9088 213.879 83.9088H207.926C207.076 83.9088 206.614 82.916 207.16 82.2654L215.713 72.0875C216.313 71.373 217.478 71.7975 217.478 72.7308Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_5451_58132">
          <rect width="344" height="120" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export function JointAccountInvited(props: { invite: JointAccountInvite }) {
  return (
    <div className="bg-white overflow-hidden flex flex-col rounded-lg col-span-12 lg:col-span-4">
      <InvitedImage />
      <div className="p-6 flex flex-col flex-1 justify-between">
        <div>
          <p className="text-sm font-normal text-gray-500">You’re invited!</p>
          <h3 className="font-medium text-lg text-gray-950 mb-2 leading-6">Accept Joint Account invitation</h3>
          <p className="font-normal text-base text-gray-900 mb-4">
            {props.invite.inviterFirstName} {props.invite.inviterLastName} has invited you to be a joint accountholder.
          </p>
        </div>
        <Link
          href={{
            pathname: PATH_DEPOSIT_APPLICATION_JOINT_ACCEPT,
          }}
          className="block w-full text-center text-white rounded-lg py-2.5 text-base bg-blue-800 hover:opacity-90"
        >
          Apply Now
        </Link>
      </div>
    </div>
  );
}
