import { MonthCashFlow } from '@/generated/graphql';
import { MonthCashflowPopover } from '@/routes/account/routes/home/components/cashflow-summary/components/cashflow-summary-chart/components/month-cashflow-popover';

function WithdrawalsItem(props: { maxWithdrawals: number; height: number; monthCashflow: MonthCashFlow }) {
  const itemHeight = (props.monthCashflow.totalWithdrawalsAmount / props.maxWithdrawals) * props.height;

  return (
    <div className="bg-yellow-300 w-3.5 rounded-full relative" style={{ height: `${itemHeight}px` }}>
      {props.monthCashflow.totalDepositsAmount < props.monthCashflow.totalWithdrawalsAmount && (
        <div
          className="bg-gray-950 w-3.5 h-3.5 rounded-full absolute"
          style={{
            top: `calc(${(-props.monthCashflow.totalNetAmount / props.monthCashflow.totalWithdrawalsAmount) * 100}% - ${
              itemHeight > 10 ? '0.875rem' : '0rem'
            })`,
          }}
        />
      )}
    </div>
  );
}
export function Withdrawals(props: {
  maxDeposits: number;
  maxWithdrawals: number;
  height: number;
  previousSixMonthsCashflow: MonthCashFlow[];
}) {
  return (
    <div className="flex items-start justify-between px-4" style={{ height: `${props.height}px` }}>
      {props.previousSixMonthsCashflow.map((monthCashflow, index) => (
        <MonthCashflowPopover key={`withdrawals-${monthCashflow.date}`} monthCashflow={monthCashflow} left={index > 2}>
          <WithdrawalsItem maxWithdrawals={props.maxWithdrawals} monthCashflow={monthCashflow} height={props.height} />
        </MonthCashflowPopover>
      ))}
    </div>
  );
}
