import { MonthCashFlow } from '@/generated/graphql';
import { toAmountInDollarsFromCents } from '@/helpers/currency';
import { isNil } from '@/helpers/is-nil';
import { UTCMonthYearLongFormatter } from '@/i18n/datetime';
import { Popover, Transition } from '@headlessui/react';
import cx from 'clsx';
import * as React from 'react';

export function MonthCashflowPopover(props: {
  children: React.ReactNode;
  monthCashflow: MonthCashFlow;
  left: boolean;
}) {
  const [show, setShow] = React.useState(false);

  function onMouseEnter() {
    setShow(true);
  }

  function onMouseLeave() {
    setShow(false);
  }

  return (
    <Popover className="relative">
      <div onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
        {props.children}
      </div>
      <Transition
        show={show}
        as={React.Fragment}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <Popover.Panel
          className={cx('absolute z-10 w-36 top-1/2 -translate-y-1/2', {
            'left-8': !props.left,
            'right-8': props.left,
          })}
        >
          <div
            className={cx(
              'absolute top-1/2 -translate-y-1/2 border-solid border-b-gray-900 border-b-[0.75rem] border-x-transparent border-x-[0.75rem] border-t-0 w-fit',
              { '-left-4 -rotate-90': !props.left, '-right-4 rotate-90': props.left },
            )}
          />
          <div className="bg-gray-900 rounded p-4">
            {isNil(props.monthCashflow.totalDepositsAmount) && isNil(props.monthCashflow.totalWithdrawalsAmount) ? (
              <p className="text-white text-sm">No data for this time period</p>
            ) : (
              <>
                <p className="text-white text-sm">
                  {UTCMonthYearLongFormatter.format(new Date(props.monthCashflow.date))}
                </p>
                <div className="flex mt-4">
                  <div className="w-3 h-3 mt-1 bg-blue-400 rounded-full mr-2" />
                  <div>
                    <p className="text-white text-sm">Money in</p>
                    <p className="text-white text-sm">
                      {toAmountInDollarsFromCents(props.monthCashflow.totalDepositsAmount)}
                    </p>
                  </div>
                </div>
                <div className="flex mt-4">
                  <div className="w-3 h-3 mt-1 bg-yellow-400 rounded-full mr-2" />
                  <div>
                    <p className="text-white text-sm">Money out</p>
                    <p className="text-white text-sm">
                      {toAmountInDollarsFromCents(props.monthCashflow.totalWithdrawalsAmount)}
                    </p>
                  </div>
                </div>
                <div className="flex mt-4">
                  <div className="w-3 h-3 mt-1 border-1 border-white rounded-full mr-2" />
                  <div>
                    <p className="text-white text-sm">Cash Flow</p>
                    <p className="text-white text-sm">
                      {props.monthCashflow.totalNetAmount > 0 ? '+' : ''}
                      {toAmountInDollarsFromCents(props.monthCashflow.totalNetAmount)}
                    </p>
                  </div>
                </div>
                <div className="w-full h-px bg-gray-400 mt-2" />
                <div className="flex mt-2">
                  <div className="w-3 h-3 mr-2" />
                  <div>
                    <p className="text-white text-sm">Savings Rate</p>
                    <p className="text-white text-sm">
                      {props.monthCashflow.totalNetAmount > 0 ? '+' : ''}
                      {props.monthCashflow.savingRate}%
                    </p>
                  </div>
                </div>
              </>
            )}
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}
