import { DashboardView } from '@/components/dashboard/dashboard-view';
import { useTemplatesDrawerContext } from '@/components/drawers/templates/hooks/use-templates-drawer-context';
import { FeatureFlagBanner } from '@/components/feature-flag-banner';
import { useFeatureWithUserStrategies } from '@/components/feature-flag/hooks/use-feature-with-user-strategies';
import { PageTitle } from '@/components/page-title';
import { URL_HMB_TO_APYS_NYCB, URL_HMB_TO_NYCB_PROMO } from '@/constants/app';
import { FEATURE_FLAG_ACCOUNTS_PAGE_V3 } from '@/constants/features';
import { useTrackOccFunnelEvents } from '@/hooks/analytics';
import { CreditOffers } from '@/routes/account/components/credit-offers';
import { AccountsOverview } from '@/routes/account/routes/accounts-overview';
import { AccountActivationLink } from '@/routes/account/routes/home/components/account-activation-link';
import { Accounts } from '@/routes/account/routes/home/components/accounts';
import { CashflowSummary } from '@/routes/account/routes/home/components/cashflow-summary';
import { GettingStarted } from '@/routes/account/routes/home/components/getting-started';
import { httpClient } from '@/transports/http';
import { UseGetDeciderV1OffersResult } from '@/types/mainframe';
import { useGetDeciderV1Offers } from '@core/mainframe-react-query';
import { useRouter } from 'next/router';
import * as React from 'react';

function useHomeLegacy() {
  const router = useRouter();
  const templatesDrawer = useTemplatesDrawerContext();

  React.useEffect(() => {
    if (router.query?.modal == 'routine-templates') {
      templatesDrawer.openTemplatesDrawer();
    }
  }, [router.query?.modal]);

  const offersQuery = useGetDeciderV1Offers<UseGetDeciderV1OffersResult>(httpClient, {});
  const trackOccFunnelEvents = useTrackOccFunnelEvents();

  function onViewInvitation() {
    trackOccFunnelEvents({
      cta_id: 'credit-offer-view',
      position: 'occ_page_offer_card',
    });
  }

  return {
    onViewInvitation,
    creditCardOffers: offersQuery.data?.creditCardOffers,
  };
}

function Footnotes() {
  return (
    <p>
      <sup>1</sup>Terms and conditions apply; see{' '}
      <a href={URL_HMB_TO_APYS_NYCB} className="underline" target="_blank" rel="noreferrer">
        Deposit Account Terms & Current APYs
      </a>{' '}
      and{' '}
      <a href={URL_HMB_TO_NYCB_PROMO} className="underline" target="_blank" rel="noreferrer">
        Deposit Account Promotion
      </a>{' '}
      for details.
    </p>
  );
}

function HomeLegacy() {
  const controller = useHomeLegacy();

  return (
    <DashboardView footnotes={<Footnotes />}>
      <PageTitle title="Home Dashboard" />
      <FeatureFlagBanner />
      {controller.creditCardOffers?.length === 0 ? null : (
        <CreditOffers onViewInvitation={controller.onViewInvitation} />
      )}
      <GettingStarted />
      <div className="pt-6 grid gap-6 grid-cols-12 max-w-screen-lg">
        <CashflowSummary />
        <AccountActivationLink />
      </div>
      <Accounts />
    </DashboardView>
  );
}

export function Home() {
  const accountsPageV3FeatureFlag = useFeatureWithUserStrategies(FEATURE_FLAG_ACCOUNTS_PAGE_V3);

  return accountsPageV3FeatureFlag.isEnabled ? <AccountsOverview /> : <HomeLegacy />;
}
