import { useFeatureWithUserStrategies } from '@/components/feature-flag/hooks/use-feature-with-user-strategies';
import {
  FEATURE_FLAG_DEPOSIT_ONBOARDING,
  FEATURE_FLAG_JOINT_ACCOUNTS,
  FEATURE_FLAG_WIND_DOWN,
} from '@/constants/features';
import {
  DepositAccountType,
  useCanOpenIndividualDepositAccountQuery,
  useGetCanOpenPendingJointAccountQuery,
  useGetDepositAccountsQuery,
  useGetJointAccountInvitesQuery,
} from '@/generated/graphql';
import { AccountActivationItem } from '@/routes/account/routes/accounts-overview/components/banner/components/account-activation-item';
import { DepositInvitationItem } from '@/routes/account/routes/accounts-overview/components/banner/components/deposit-invitation-item';
import { JointInvitationItem } from '@/routes/account/routes/accounts-overview/components/banner/components/joint-invitation-item';
import { JointInvitedItem } from '@/routes/account/routes/accounts-overview/components/banner/components/joint-invited-item';
import { Navigation } from '@/routes/account/routes/accounts-overview/components/banner/components/navigation';
import { RoutinesItem } from '@/routes/account/routes/accounts-overview/components/banner/components/routines-item';
import * as ClosedBannesStorage from '@/routes/account/routes/accounts-overview/components/banner/helpers/closed-banners-storage';
import { OverviewBanners } from '@/routes/account/routes/accounts-overview/components/banner/helpers/types';
import { ErrorIcon } from '@/routes/account/routes/accounts-overview/components/images';
import * as DraftRoutinesStorage from '@/routes/account/routes/routines/helpers/draft-storage';
import React from 'react';
import { Manipulation } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';

function isJointAccount(account: { accountType: DepositAccountType; isPendingJointAccount: boolean }) {
  return account?.accountType === DepositAccountType.Joint || account?.isPendingJointAccount;
}

function isIndividualAccount(account: { accountType: DepositAccountType }) {
  return account?.accountType === DepositAccountType.Individual;
}

function useBanner() {
  const draftRoutines = DraftRoutinesStorage.getDrafts();
  const closedBanners = ClosedBannesStorage.getClosedBanners();
  const [bannersToShow, setBannersToShow] = React.useState<OverviewBanners[]>([]);

  const getDepositAccountsQuery = useGetDepositAccountsQuery();
  const depositAccounts = getDepositAccountsQuery.data?.depositAccounts || [];
  const depositAccountsWithoutPlans = depositAccounts.filter((account) => account.plans.length === 0);

  const depositOnboardingFeatureFlag = useFeatureWithUserStrategies(FEATURE_FLAG_DEPOSIT_ONBOARDING);
  const windDownFeatureFlag = useFeatureWithUserStrategies(FEATURE_FLAG_WIND_DOWN);
  const jointAccountsFeatureFlag = useFeatureWithUserStrategies(FEATURE_FLAG_JOINT_ACCOUNTS);
  const canOpenIndividualDepositAccountQuery = useCanOpenIndividualDepositAccountQuery(
    {},
    {
      enabled: depositOnboardingFeatureFlag.isEnabled,
    },
  );
  const canOpenJointDepositAccountQuery = useGetCanOpenPendingJointAccountQuery(
    {},
    {
      enabled: jointAccountsFeatureFlag.isEnabled,
    },
  );
  const getJointAccountInvitesQuery = useGetJointAccountInvitesQuery(
    {},
    { enabled: jointAccountsFeatureFlag.isEnabled },
  );

  const canOpenIndividualDepositAccount =
    depositOnboardingFeatureFlag.isEnabled &&
    canOpenIndividualDepositAccountQuery.data?.canOpenIndividualDepositAccount;
  const canOpenJointDepositAccount =
    jointAccountsFeatureFlag.isEnabled && canOpenJointDepositAccountQuery.data?.canOpenPendingJointAccount;
  const jointAccountInvites = getJointAccountInvitesQuery.data?.jointAccountInvites || [];
  const activeJointAccountInvite = jointAccountInvites[0];

  const isFetched =
    getDepositAccountsQuery.isFetched &&
    canOpenIndividualDepositAccountQuery.isFetched &&
    canOpenJointDepositAccountQuery.isFetched &&
    getJointAccountInvitesQuery.isFetched;

  React.useEffect(() => {
    if (isFetched) {
      const banners = [];

      if (
        !windDownFeatureFlag.isEnabled &&
        canOpenIndividualDepositAccount &&
        !depositAccounts.find(isIndividualAccount) &&
        !closedBanners.includes(OverviewBanners.DepositInvitation)
      ) {
        banners.push(OverviewBanners.DepositInvitation);
      }

      if (
        !windDownFeatureFlag.isEnabled &&
        canOpenJointDepositAccount &&
        !depositAccounts.find(isJointAccount) &&
        !closedBanners.includes(OverviewBanners.JointInvitation)
      ) {
        banners.push(OverviewBanners.JointInvitation);
      }

      if (
        !windDownFeatureFlag.isEnabled &&
        depositAccountsWithoutPlans.length > 0 &&
        !closedBanners.includes(OverviewBanners.AccountActivation)
      ) {
        banners.push(OverviewBanners.AccountActivation);
      }

      if (
        !windDownFeatureFlag.isEnabled &&
        !!draftRoutines &&
        draftRoutines.length > 0 &&
        !closedBanners.includes(OverviewBanners.Routines)
      ) {
        banners.push(OverviewBanners.Routines);
      }

      if (
        !windDownFeatureFlag.isEnabled &&
        jointAccountsFeatureFlag.isEnabled &&
        !!activeJointAccountInvite &&
        !closedBanners.includes(OverviewBanners.JointInvited)
      ) {
        banners.push(OverviewBanners.JointInvited);
      }

      setBannersToShow(banners);
    }
  }, [isFetched]);

  return {
    isLoading:
      getDepositAccountsQuery.isLoading ||
      canOpenIndividualDepositAccountQuery.isLoading ||
      canOpenJointDepositAccountQuery.isLoading ||
      getJointAccountInvitesQuery.isLoading,
    isError:
      getDepositAccountsQuery.isError ||
      canOpenIndividualDepositAccountQuery.isError ||
      canOpenJointDepositAccountQuery.isError ||
      getJointAccountInvitesQuery.isError,
    depositAccounts,
    depositAccountsWithoutPlans,
    activeJointAccountInvite,
    bannersToShow,
  };
}

export function Banner() {
  const controller = useBanner();
  const [, forseRerender] = React.useState({});

  function onSwiperStateChange() {
    forseRerender({});
  }

  function onClose(banner: OverviewBanners) {
    onSwiperStateChange();
    ClosedBannesStorage.addClosedBanner(banner);
  }

  if (controller.isLoading) {
    return (
      <div className="grid grid-cols-12 mb-4">
        <div className="col-span-12">
          <div className="bg-white rounded-lg flex items-center p-6">
            <div className="bg-gray-100 rounded-lg w-28 h-28 mr-6" />
            <div>
              <div className="bg-gray-100 rounded-lg w-36 h-4 mb-1.5" />
              <div className="bg-gray-100 rounded-lg w-36 h-3 mb-3" />
              <div className="bg-gray-100 rounded-lg w-20 h-10" />
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (controller.isError) {
    return (
      <div className="grid grid-cols-12 mb-4">
        <div className="col-span-12">
          <div className="lg:col-span-1 col-span-3 rounded-lg bg-white py-6 px-7 flex flex-row items-center">
            <ErrorIcon />
            <p className="text-base text-red-900 font-normal ml-4">
              We’re having trouble loading your account right now
            </p>
          </div>
        </div>
      </div>
    );
  }

  if (controller.bannersToShow.length !== 0)
    return (
      <div className="grid grid-cols-12 mb-4">
        <div className="col-span-12">
          <div>
            <Swiper
              onSlideChange={() => onSwiperStateChange()}
              spaceBetween={24}
              slidesPerView={1}
              modules={[Manipulation]}
            >
              {controller.bannersToShow.includes(OverviewBanners.DepositInvitation) && (
                <SwiperSlide className="h-auto" key="deposit-invitation-item">
                  <DepositInvitationItem onClose={() => onClose(OverviewBanners.DepositInvitation)} />
                </SwiperSlide>
              )}
              {controller.bannersToShow.includes(OverviewBanners.JointInvitation) && (
                <SwiperSlide className="h-auto" key="joint-invitation-item">
                  <JointInvitationItem onClose={() => onClose(OverviewBanners.JointInvitation)} />
                </SwiperSlide>
              )}
              {controller.bannersToShow.includes(OverviewBanners.JointInvited) && (
                <SwiperSlide className="h-auto" key="joint-invited-item">
                  <JointInvitedItem activeInvite={controller.activeJointAccountInvite} />
                </SwiperSlide>
              )}
              {controller.bannersToShow.includes(OverviewBanners.AccountActivation) && (
                <SwiperSlide className="h-auto" key="account-activation-item">
                  <AccountActivationItem
                    onClose={() => onClose(OverviewBanners.AccountActivation)}
                    accounts={controller.depositAccounts}
                  />
                </SwiperSlide>
              )}
              {controller.bannersToShow.includes(OverviewBanners.Routines) && (
                <SwiperSlide className="h-auto" key="routines-item">
                  <RoutinesItem onClose={() => onClose(OverviewBanners.Routines)} />
                </SwiperSlide>
              )}
              <Navigation />
            </Swiper>
          </div>
        </div>
      </div>
    );
}
