import { ArrowIcon } from './images';

export function CardAccountLoading() {
  return (
    <div className="flex-1 bg-white rounded-lg p-6">
      <div className="flex flex-row items-center justify-between mb-8 mt-1.5">
        <div className="h-4 w-16 sm:w-28 bg-gray-100 rounded-lg animate-pulse" />
        <ArrowIcon />
      </div>
      <div className="flex items-end justify-between">
        <div>
          <div className="h-4 w-16 mb-1.5 sm:w-28 bg-gray-100 rounded-lg animate-pulse" />
          <div className="h-3 w-16 sm:w-28 bg-gray-100 rounded-lg animate-pulse" />
        </div>
        <div className="h-3 w-16 sm:w-28 bg-gray-100 rounded-lg animate-pulse" />
      </div>
    </div>
  );
}
