import { CREDIT_CARD_TYPES } from '@/components/credit';
import COLORS from '@/styles/colors';

export const creditCardColors = {
  TEAL: {
    id: 'TEAL',
    color: 'TEAL',
    name: 'Teal/Black',
    type: CREDIT_CARD_TYPES.credit,
    lineColor: COLORS.brandTeal,
    backgroundColor: COLORS.black,
    textColor: COLORS.white,
  },

  WHITE: {
    id: 'WHITE',
    color: 'WHITE',
    name: 'Black/White',
    type: CREDIT_CARD_TYPES.credit,
    backgroundColor: COLORS.white,
    lineColor: COLORS.black,
    logoColor: COLORS.black,
    textColor: COLORS.black,
  },

  GREEN: {
    id: 'GREEN',
    color: 'GREEN',
    name: 'Green/Teal',
    type: CREDIT_CARD_TYPES.credit,
    backgroundColor: COLORS.brandTeal,
    lineColor: COLORS.creditGreen,
    textColor: COLORS.white,
  },

  PINK: {
    id: 'PINK',
    color: 'PINK',
    name: 'Pink/Teal',
    type: CREDIT_CARD_TYPES.credit,
    backgroundColor: COLORS.brandTeal,
    lineColor: COLORS.creditPink,
    textColor: COLORS.white,
  },
};
