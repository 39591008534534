import { MonthCashFlow } from '@/generated/graphql';
import { DateTime } from 'luxon';

function formatCashflowMonth(monthCashflow: MonthCashFlow) {
  return DateTime.fromISO(monthCashflow.date).toLocaleString({ month: 'short' });
}

export function Months(props: { previousSixMonthsCashflow: MonthCashFlow[] }) {
  return (
    <div className="flex items-center justify-between mt-6 mx-4 pr-[50px]">
      {props.previousSixMonthsCashflow.map((monthCashflow) => (
        <span key={`month-${monthCashflow.date}`} className="text-sm text-gray-500">
          {formatCashflowMonth(monthCashflow)}
        </span>
      ))}
    </div>
  );
}
