import { NYCB_TRANSFER_PATH } from '@/constants/routes';
import { DepositAccount } from '@/generated/graphql';
import { CashflowSummaryHeader } from '@/routes/account/routes/home/components/cashflow-summary/components/cashflow-summary-header';
import { getLastSixMonthsCopy } from '@/routes/account/routes/home/components/cashflow-summary/helpers';
import Link from 'next/link';

function EmptyChartImage() {
  return (
    <svg width="181" height="102" viewBox="0 0 181 102" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="60.6667" width="180" height="2" fill="white" />
      <rect x="14.9999" y="39.0667" width="10" height="22.4" rx="5" fill="#D7DDE2" />
      <rect x="14.9999" y="61.4668" width="10" height="12.8" rx="5" fill="#D7DDE2" />
      <rect x="41" y="22.2668" width="10" height="39.2" rx="5" fill="#D7DDE2" />
      <rect x="41" y="61.4668" width="10" height="28" rx="5" fill="#D7DDE2" />
      <rect x="67.9999" y="23.0667" width="10" height="38.4" rx="5" fill="#D7DDE2" />
      <rect x="67.9999" y="61.4668" width="10" height="16" rx="5" fill="#D7DDE2" />
      <rect x="93.9999" y="12.6667" width="10" height="48.8" rx="5" fill="#D7DDE2" />
      <rect x="93.9999" y="61.4668" width="10" height="20" rx="5" fill="#D7DDE2" />
      <circle cx="19.9999" cy="50.4668" r="5" fill="#8998A1" />
      <circle cx="46" cy="46.4668" r="5" fill="#8998A1" />
      <circle cx="72.9999" cy="44.0667" r="5" fill="#8998A1" />
      <circle cx="98.9999" cy="37.6667" r="5" fill="#8998A1" />
      <rect x="121" y="16.5667" width="10" height="45" rx="5" fill="#D7DDE2" />
      <rect x="121" y="61.5667" width="10" height="19" rx="5" fill="#D7DDE2" />
      <circle cx="126" cy="39.5667" r="5" fill="#8998A1" />
      <rect x="148" y="12.6667" width="10" height="48.8" rx="5" fill="#D7DDE2" />
      <rect x="148" y="61.5667" width="10" height="12" rx="5" fill="#D7DDE2" />
      <circle cx="153" cy="29.6667" r="5" fill="#8998A1" />
    </svg>
  );
}

export function CashflowSummaryEmpty(props: {
  selectedAccountId: string | null;
  onChangeAccount: (value: string) => void;
  accounts: DepositAccount[];
}) {
  return (
    <div className="w-full flex flex-col mb-6">
      <CashflowSummaryHeader
        text={getLastSixMonthsCopy()}
        withAccountsDropdown
        accounts={props.accounts}
        selectedAccountId={props.selectedAccountId}
        onChangeAccount={props.onChangeAccount}
      />
      <div className="bg-white-background rounded-xl flex-1 mt-4 flex flex-col items-center justify-center py-6">
        <EmptyChartImage />
        <p className="text-gray-600 text-center font-normal text-base mt-4">Make your first deposit to start saving</p>
        <p className="text-gray-400 text-sm text-center font-normal w-2/3 mb-3 mt-1">
          Transfer in at least $500.00 this month and save more than you spend to be cash flow positive.
        </p>
        <Link
          href={NYCB_TRANSFER_PATH}
          passHref
          className="border-gray-700 border-3 rounded px-2 py-1 text-sm bg-gray-700 text-white"
        >
          Transfer money in
        </Link>
      </div>
    </div>
  );
}
