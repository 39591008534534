import * as React from 'react';

import { DepositAccount, MonthCashFlow } from '@/generated/graphql';
import { Deposits } from '@/routes/account/routes/home/components/cashflow-summary/components/cashflow-summary-chart/components/deposits';
import { Legend } from '@/routes/account/routes/home/components/cashflow-summary/components/cashflow-summary-chart/components/legend';
import { Months } from '@/routes/account/routes/home/components/cashflow-summary/components/cashflow-summary-chart/components/months';
import { Withdrawals } from '@/routes/account/routes/home/components/cashflow-summary/components/cashflow-summary-chart/components/withdrawals';
import { CashflowSummaryHeader } from '@/routes/account/routes/home/components/cashflow-summary/components/cashflow-summary-header';

import {
  generatePreviousSixMonthsCashFlow,
  getLastSixMonthsCopy,
} from '@/routes/account/routes/home/components/cashflow-summary/helpers';

function getMaxValue(previousSixMonthsCashflow: MonthCashFlow[], key: string) {
  return previousSixMonthsCashflow.reduce(
    (acc, monthCashflow) => (acc > monthCashflow[key] ? acc : monthCashflow[key]),
    0,
  );
}

const CHART_HEIGHT = 220;
const LINE_HEIGHT = 2;

export function CashflowSummaryChart(props: {
  selectedAccountId: string | null;
  onChangeAccount: (value: string) => void;
  accounts: DepositAccount[];
  monthlyCashflow: MonthCashFlow[];
}) {
  const previousSixMonthsCashflow = generatePreviousSixMonthsCashFlow(props.monthlyCashflow);

  const maxDeposits = getMaxValue(previousSixMonthsCashflow, 'totalDepositsAmount');
  const maxWithdrawals = getMaxValue(previousSixMonthsCashflow, 'totalWithdrawalsAmount');

  const depositsHeight = (maxDeposits / (maxDeposits + maxWithdrawals)) * (CHART_HEIGHT - LINE_HEIGHT);
  const withdrawalsHeight = (maxWithdrawals / (maxDeposits + maxWithdrawals)) * (CHART_HEIGHT - LINE_HEIGHT);

  return (
    <div className="min-h-72 w-full pb-5">
      <CashflowSummaryHeader
        text={getLastSixMonthsCopy()}
        withAccountsDropdown
        accounts={props.accounts}
        selectedAccountId={props.selectedAccountId}
        onChangeAccount={props.onChangeAccount}
      />
      <div className="mt-5">
        <div className="flex">
          <div className="flex-1 w-full flex flex-col justify-center" style={{ height: `${CHART_HEIGHT}px` }}>
            <Deposits
              maxDeposits={maxDeposits}
              maxWithdrawals={maxWithdrawals}
              height={depositsHeight}
              previousSixMonthsCashflow={previousSixMonthsCashflow}
            />
            <div className="w-full bg-gray-100" style={{ height: `${LINE_HEIGHT}px` }} />
            <Withdrawals
              maxDeposits={maxDeposits}
              maxWithdrawals={maxWithdrawals}
              height={withdrawalsHeight}
              previousSixMonthsCashflow={previousSixMonthsCashflow}
            />
          </div>
          <Legend
            maxDeposits={maxDeposits}
            maxWithdrawals={maxWithdrawals}
            depositsHeight={depositsHeight}
            withdrawalsHeight={withdrawalsHeight}
          />
        </div>
        <Months previousSixMonthsCashflow={previousSixMonthsCashflow} />
      </div>
    </div>
  );
}
