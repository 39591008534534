import { useFeatureWithUserStrategies } from '@/components/feature-flag/hooks/use-feature-with-user-strategies';
import { FEATURE_FLAG_ACCOUNT_ACTIVATION } from '@/constants/features';
import { PATH_ACCOUNT_ACTIVATION } from '@/constants/routes';
import { DepositAccount, DepositAccountType, useGetDepositAccountsQuery } from '@/generated/graphql';
import { routeFor } from '@/helpers/router';
import Link from 'next/link';
import React from 'react';

function AccountActivationImage() {
  const clipPath0 = React.useId();

  return (
    <svg viewBox="0 0 334 120" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath={`url(#${clipPath0})`}>
        <rect width="333.33" height="120" transform="translate(0.669922)" fill="#D3F5F8" />
        <rect x="62.0183" y="80.3291" width="40.0269" height="40.0269" fill="#D3F5F8" />
        <rect x="142.072" y="80.3291" width="40.0269" height="40.0269" fill="#56CBE3" />
        <rect x="182.099" y="80.3291" width="40.0269" height="40.0269" fill="#39B8D5" />
        <rect x="142" width="120" height="87" fill="#43EA79" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M142 0L221.126 3.05176e-05V0.0122782C199.482 0.543006 182.099 18.255 182.099 40.0269L142 40.0269V0ZM182.099 40.0269L142 40.0269V80.5C164.106 80.5 182.099 62.1331 182.099 40.0269Z"
          fill="#D3F5F8"
        />
        <path d="M182.099 80.3291H222.126C222.126 102.435 204.205 120.356 182.099 120.356V80.3291Z" fill="#1EA1C1" />
        <path
          d="M61.9999 80C39.9061 80.0146 22 97.9296 22 120.027H62.0269V120H142V80H62.0269C62.0179 80 62.0089 80 61.9999 80Z"
          fill="#96FAB5"
        />
        <rect x="102" y="120" width="40" height="160" rx="20" transform="rotate(-90 102 120)" fill="#FDC03F" />
        <circle cx="121.865" cy="100" r="20" fill="#0B1012" />
        <path d="M262 0H302V68C302 79.0457 293.046 88 282 88C270.954 88 262 79.0457 262 68V0Z" fill="#96FAB5" />
        <path
          d="M262 119.975H302V68.204C302 57.1583 293.046 48.204 282 48.204C270.954 48.204 262 57.1583 262 68.204V119.975Z"
          fill="#43EA79"
        />
        <circle cx="282" cy="68" r="20" fill="#F5F6F8" />
        <rect x="222" y="19" width="40" height="82" fill="#0B1012" />
        <circle cx="242.058" cy="100.293" r="20.0586" fill="#3F4E56" />
        <circle cx="242.058" cy="20.0586" r="20.0586" fill="#FDC03F" />
      </g>
      <defs>
        <clipPath id={clipPath0}>
          <rect width="333.33" height="120" fill="white" transform="translate(0.669922)" />
        </clipPath>
      </defs>
    </svg>
  );
}

function byAccountType(account1: DepositAccount, account2: DepositAccount) {
  if (account1.accountType === DepositAccountType.Individual) {
    return -1;
  }

  if (account2.accountType === DepositAccountType.Individual) {
    return 1;
  }

  return 0;
}

export function AccountActivationLink() {
  const accountActivationFeatureFlag = useFeatureWithUserStrategies(FEATURE_FLAG_ACCOUNT_ACTIVATION);
  const getDepositAccountsQuery = useGetDepositAccountsQuery();

  const depositAccounts = getDepositAccountsQuery.data?.depositAccounts || [];
  const depositAccountsWithoutPlans = depositAccounts.filter((account) => account.plans.length === 0);

  if (!accountActivationFeatureFlag.isEnabled || depositAccountsWithoutPlans.length === 0) {
    return null;
  }

  return (
    <div className="bg-white overflow-hidden flex flex-col rounded-lg col-span-12 lg:col-span-4">
      <AccountActivationImage />
      <div className="p-6 flex flex-col flex-1 justify-between">
        <div>
          <h3 className="text-md text-gray-950 mb-1 leading-6">Build your savings</h3>
          <h3 className="font-normal text-base text-gray-900 mb-2 leading-5">
            Set up your deposit account(s) and see how you can build your savings quickly and reliably with HMBradley!
          </h3>
        </div>
        <div className="flex flex-col mt-2">
          {[...depositAccountsWithoutPlans].sort(byAccountType).map((account) => (
            <Link
              key={`account-activation-link-${account.id}`}
              href={{
                pathname: routeFor(PATH_ACCOUNT_ACTIVATION, {
                  path: {
                    depositAccountId: account.id,
                  },
                }),
              }}
              className="border-1 border-gray-200 rounded-md py-2 mt-2 text-gray-800 text-sm text-center"
            >
              {account.accountType === DepositAccountType.Individual ? 'Deposit account' : 'Joint deposit account'}{' '}
              {account.depositAccountNumber.lastFour}
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
}
