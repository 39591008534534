import { useGetClosedDepositAccountsQuery, useGetDepositAccountsQuery } from '@/generated/graphql';
import { isHatchClosedByHmb } from '@/helpers';
import { useHasDepositAccount } from '@/hooks/use-has-deposit_account';
import { httpClient } from '@/transports/http';
import { useGetLedgerV2Accounts } from '@core/mainframe-react-query';

export function useSwitchToNycb() {
  const depositAccounts = useHasDepositAccount();
  const getLedgerV2AccountsQuery = useGetLedgerV2Accounts(httpClient, {
    options: {
      select(data) {
        return {
          isClosedByHmb: data?.data?.length && data?.data?.some(isHatchClosedByHmb),
        };
      },
    },
  });
  const getDepositAccountsQuery = useGetDepositAccountsQuery({});
  const getClosedDepositAccountsQuery = useGetClosedDepositAccountsQuery({});

  const isClosedByHmb =
    !getLedgerV2AccountsQuery.isLoading &&
    !getLedgerV2AccountsQuery.isError &&
    getLedgerV2AccountsQuery.data.isClosedByHmb;
  const hadNycbDepositAccount =
    !getDepositAccountsQuery.isLoading &&
    !getClosedDepositAccountsQuery.isLoading &&
    ((getDepositAccountsQuery.data?.depositAccounts || []).length >= 1 ||
      (getClosedDepositAccountsQuery.data?.depositAccounts || []).length >= 1);

  const showClosedHatchContent = isClosedByHmb && !hadNycbDepositAccount;

  return {
    isLoading: getLedgerV2AccountsQuery.isLoading || depositAccounts.isLoading,
    isError: getLedgerV2AccountsQuery.isError || depositAccounts.isError,
    hasDepositAccount: depositAccounts.hasDepositAccount,
    isClosedByHmb,
    showClosedHatchContent,
  };
}
