import { FeatureFlag } from '@/components/feature-flag';
import { CloseAccountInfoBanner } from '@/components/wind-down-banners/close-account-info-banner';
import { ClosedAccountBanner } from '@/components/wind-down-banners/closed-account-banner';
import { FEATURE_FLAG_WIND_DOWN_BANNERS } from '@/constants/features';
import { CREDIT_STATEMENTS_PATH, PATH_ACCOUNT_CREDIT_DETAILS } from '@/constants/routes';
import { routeFor } from '@/helpers/router';
import { formatDate } from '@/i18n/datetime';
import { CurrencyFormatter } from '@/i18n/numbers';
import { useRouter } from 'next/router';

import { ArrowIcon, CreditCardAutopayIcon } from '@/routes/account/routes/accounts-overview/components/images';
import Link from 'next/link';

export function CardCreditAccount(props: {
  creditCard?: {
    id?: string;
    isActive?: boolean;
    lastFour?: string;
    issuanceRequestedAt?: string;
    latestIssuance?: {
      statusCode?: string;
    };
    isAccountClosed?: boolean;
  };
  creditCardBalance?: {
    currentBalance?: string;
    remainingStatementBalance?: string;
    nextPaymentDueDate?: string | null;
  };
  creditCardAutopay?: {
    archivedDate?: string | null;
  };
}) {
  const router = useRouter();
  if (!props.creditCard || !props.creditCardBalance) {
    return null;
  }

  if (props.creditCard.isAccountClosed && Number(props.creditCardBalance.remainingStatementBalance) <= 0) {
    const pushToStatements = () => {
      router.push(routeFor(CREDIT_STATEMENTS_PATH, { path: { cardId: props.creditCard?.id } }));
    };
    return (
      <div
        className="basis-1/4 flex-1 flex flex-col bg-white rounded-lg p-2 cursor-pointer min-w-lg md:max-w-[50%]"
        onClick={pushToStatements}
      >
        <ClosedAccountBanner />
        <div className="flex flex-col justify-between gap-4 grow hover:bg-gray-50 p-4 duration-100 rounded-md">
          <div className="flex flex-row items-center justify-between mb-6">
            <p className="text-black">Credit - {props.creditCard.lastFour} (Closed)</p>
          </div>
          <div className="flex items-end justify-between">
            Statements
            <ArrowIcon />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="basis-1/4 flex-1 bg-white rounded-lg p-2 cursor-pointer md:max-w-[50%]">
      <Link
        href={{
          pathname: routeFor(PATH_ACCOUNT_CREDIT_DETAILS, {
            path: {
              cardId: props.creditCard.id,
            },
          }),
        }}
        passHref
      >
        <FeatureFlag flag={FEATURE_FLAG_WIND_DOWN_BANNERS}>
          <CloseAccountInfoBanner title="You will no longer be able to use your credit card for purchases starting 12/07/23." />
        </FeatureFlag>
        <div className="hover:bg-gray-50 p-4 duration-100 rounded-md">
          <div className="flex flex-row items-center justify-between mb-6">
            <p className="text-black text-base">Credit Card</p>
            <ArrowIcon />
          </div>
          <div className="flex items-end justify-between">
            <div>
              <p className="text-black sm:text-md text-base">
                {CurrencyFormatter.format(parseFloat(props.creditCardBalance.currentBalance || '0.00'))}
              </p>
              <p className="text-sm font-normal text-gray-500 whitespace-nowrap">Current balance</p>
            </div>
            <div className="flex flex-col items-end">
              {!!props.creditCardAutopay && !props.creditCardAutopay.archivedDate && (
                <div className="bg-green-300 py-1 px-2.5 rounded-full flex flex-row items-center mb-0.5 w-fit">
                  <CreditCardAutopayIcon />
                  <p className="text-tiny text-gray-950 ml-1">Auto-Pay</p>
                </div>
              )}
              <p className="text-gray-500 text-sm font-normal">
                {(!!props.creditCardBalance.nextPaymentDueDate &&
                  parseFloat(props.creditCardBalance.remainingStatementBalance)) > 0
                  ? `Payment due: ${formatDate(props.creditCardBalance.nextPaymentDueDate)}`
                  : 'No payment due'}
              </p>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
}
