import { FULLSTORY_EXCLUDE } from '@/constants';
import { NYCB_PLANS_PATH, PATH_ACCOUNT_DEPOSIT_DETAILS } from '@/constants/routes';
import { routeFor } from '@/helpers/router';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import Link from 'next/link';
import { ReactNode } from 'react';

export function LastFour(props: { lastFour?: string; isHatch?: boolean }) {
  return (
    <div className="flex flex-col">
      <p className={clsx('flex items-center gap-0.5 text-black-lighter text-sm font-normal', FULLSTORY_EXCLUDE)}>
        ****
        {props?.lastFour && (
          <>
            <span>{props.lastFour}</span> {props.isHatch && <span> - Hatch Bank</span>}
          </>
        )}
      </p>
    </div>
  );
}

export function DepositCardCta(props: { id?: string }) {
  if (props.id) {
    return (
      <div className="flex gap-2 self-end">
        <Link
          href={routeFor(NYCB_PLANS_PATH, {
            path: {
              depositAccountId: props.id,
            },
          })}
          passHref
          className="border-black-lightest border-3 rounded-md px-2 py-1 text-sm truncate"
        >
          View Plans
        </Link>
        <Link
          href={{
            pathname: routeFor(PATH_ACCOUNT_DEPOSIT_DETAILS, {
              path: {
                depositAccountId: props.id,
              },
            }),
          }}
          passHref
          className="border-black-lightest border-3 rounded-md px-2 py-1 text-sm truncate"
        >
          View Account
        </Link>
      </div>
    );
  } else {
    return (
      <div className="flex gap-2 self-end">
        <div className="animate-pulse">
          <a className="border-black-lightest border-3 rounded-md px-2 py-1 text-sm truncate opacity-40 pointer-events-none">
            View Plans
          </a>
        </div>
        <div className="animate-pulse">
          <a className="border-black-lightest border-3 rounded-md px-2 py-1 text-sm truncate opacity-40 pointer-events-none">
            View Account
          </a>
        </div>
      </div>
    );
  }
}

export function CardSkeleton(props: { isError?: boolean; children?: ReactNode }) {
  return (
    <div className="flex flex-col justify-between h-full col-span-12 lg:col-span-4 p-6 gap-6 bg-white rounded-xl">
      <div className="flex flex-col gap-3">
        {props.isError && (
          <div className="flex p-2 gap-2 items-center border-2 border-red-light rounded-lg">
            <ExclamationCircleIcon className="w-8 h-8 text-red" />
            <p className="text-red text-sm font-normal">We’re having trouble loading your account right now</p>
          </div>
        )}
        <div className="flex flex-col">
          <div className="flex items-center gap-0.5 text-black-lighter text-sm font-normal">
            <div className="h-5 w-12 bg-white-background rounded-lg animate-pulse" />
          </div>
        </div>
        <div className="grid grid-cols-2 gap-3">
          {Array(8)
            .fill(' ')
            .map((_, index) => {
              return <div key={index} className="h-5 w-full bg-white-background rounded-lg animate-pulse" />;
            })}
        </div>
      </div>
      {props.children && props.children}
    </div>
  );
}
