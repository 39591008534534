import { FeatureFlag } from '@/components/feature-flag';
import { useFeatureWithUserStrategies } from '@/components/feature-flag/hooks/use-feature-with-user-strategies';
import {
  FEATURE_FLAG_DEPOSIT_ONBOARDING,
  FEATURE_FLAG_JOINT_ACCOUNTS,
  FEATURE_FLAG_SHOW_CREDIT_INSIGHTS_CARD,
} from '@/constants/features';
import { PATH_DEPOSIT_APPLICATION_ENTRY, PATH_DEPOSIT_APPLICATION_INDIVIDUAL } from '@/constants/routes';
import {
  DepositAccountType,
  useCanOpenIndividualDepositAccountQuery,
  useGetCanOpenPendingJointAccountQuery,
  useGetDepositAccountsQuery,
  useGetJointAccountInvitesQuery,
} from '@/generated/graphql';
import { AcceptedJointInviteModal } from '@/routes/account/routes/home/components/accepted-joint-invite-modal';
import { Credit } from '@/routes/account/routes/home/components/credit';
import { CreditInsights } from '@/routes/account/routes/home/components/credit-insights';
import { Deposit } from '@/routes/account/routes/home/components/deposit';
import { JointAccountInvited } from '@/routes/account/routes/home/components/joint-account-invited';
import { JointDeposit } from '@/routes/account/routes/home/components/joint-deposit';
import { NewJointAccountLink } from '@/routes/account/routes/home/components/new-joint-account-link';
import { NycbDeposit } from '@/routes/account/routes/home/components/nycb-deposit';
import { NycbJointDeposit } from '@/routes/account/routes/home/components/nycb-joint-deposit';
import { OpenDepositInvitation } from '@/routes/account/routes/home/components/open-deposit-invitation';
import { OpenJointInvitation } from '@/routes/account/routes/home/components/open-joint-invitation';
import { forwardToDepositApplicationStorage } from '@/storages/local';
import { httpClient } from '@/transports/http';
import { useGetLedgerV4Accounts } from '@core/mainframe-react-query';
import { useRouter } from 'next/router';
import * as React from 'react';

function isJointAccount(account: { accountType: DepositAccountType; isPendingJointAccount: boolean }) {
  return account?.accountType === DepositAccountType.Joint || account?.isPendingJointAccount;
}

function useAccounts() {
  const router = useRouter();

  const depositOnboardingFeatureFlag = useFeatureWithUserStrategies(FEATURE_FLAG_DEPOSIT_ONBOARDING);
  const jointAccountsFeatureFlag = useFeatureWithUserStrategies(FEATURE_FLAG_JOINT_ACCOUNTS);

  const canOpenIndividualDepositAccountQuery = useCanOpenIndividualDepositAccountQuery(
    {},
    {
      enabled: depositOnboardingFeatureFlag.isEnabled,
    },
  );
  const canOpenJointDepositAccountQuery = useGetCanOpenPendingJointAccountQuery(
    {},
    {
      enabled: jointAccountsFeatureFlag.isEnabled,
    },
  );

  const getDepositAccountsQuery = useGetDepositAccountsQuery();
  const getJointAccountInvitesQuery = useGetJointAccountInvitesQuery(
    {},
    { enabled: jointAccountsFeatureFlag.isEnabled },
  );
  const getLedgerV4AccountsQuery = useGetLedgerV4Accounts(httpClient, {});

  const canOpenIndividualDepositAccount =
    depositOnboardingFeatureFlag.isEnabled &&
    canOpenIndividualDepositAccountQuery.data?.canOpenIndividualDepositAccount;
  const canOpenJointDepositAccount =
    jointAccountsFeatureFlag.isEnabled && canOpenJointDepositAccountQuery.data?.canOpenPendingJointAccount;

  const depositAccounts = getDepositAccountsQuery.data?.depositAccounts || [];
  const depositAccountsFetched = getDepositAccountsQuery.isFetched;
  const hasActiveNycbAccount = depositAccounts.length > 0;
  const noHatchDepositAccounts = getLedgerV4AccountsQuery.data?.data?.length === 0;
  const hasJointAccount = depositAccounts.some(isJointAccount);
  const jointAccountInvites = getJointAccountInvitesQuery.data?.jointAccountInvites || [];
  const hasActiveJointAccountInvite = jointAccountsFeatureFlag.isEnabled && jointAccountInvites.length > 0;

  React.useEffect(() => {
    if (
      (canOpenIndividualDepositAccount || canOpenJointDepositAccount) &&
      depositAccountsFetched &&
      !hasActiveNycbAccount &&
      noHatchDepositAccounts &&
      forwardToDepositApplicationStorage.get()?.enabled
    ) {
      forwardToDepositApplicationStorage.set({ enabled: false });
      router.push(
        jointAccountsFeatureFlag.isEnabled ? PATH_DEPOSIT_APPLICATION_ENTRY : PATH_DEPOSIT_APPLICATION_INDIVIDUAL,
      );
    }
  }, [
    canOpenIndividualDepositAccount,
    canOpenJointDepositAccount,
    hasActiveNycbAccount,
    depositAccountsFetched,
    noHatchDepositAccounts,
    jointAccountsFeatureFlag.isEnabled,
  ]);

  return {
    displayDepositInvitation: canOpenIndividualDepositAccount,
    displayJointInvitation: canOpenJointDepositAccount && depositAccountsFetched && !hasActiveNycbAccount,
    displayJointInvited: jointAccountsFeatureFlag.isEnabled && hasActiveJointAccountInvite,
    displayNewJointAccountLink:
      canOpenJointDepositAccount && depositAccountsFetched && hasActiveNycbAccount && !hasJointAccount,
    activeJointAccountInvite: jointAccountInvites[0],
    displayAcceptedJointInviteModal: jointAccountsFeatureFlag.isEnabled,
  };
}

export function Accounts() {
  const controller = useAccounts();

  return (
    <div className="py-6 grid gap-6 grid-cols-12 max-w-screen-lg">
      {/* <Rewards /> */}
      <NycbDeposit />
      <NycbJointDeposit />
      <Credit />
      <FeatureFlag flag={FEATURE_FLAG_SHOW_CREDIT_INSIGHTS_CARD}>
        <CreditInsights />
      </FeatureFlag>
      {controller.displayDepositInvitation && <OpenDepositInvitation />}
      {controller.displayJointInvitation && <OpenJointInvitation />}
      {controller.displayJointInvited && <JointAccountInvited invite={controller.activeJointAccountInvite} />}
      <Deposit />
      <JointDeposit />
      {controller.displayNewJointAccountLink && <NewJointAccountLink />}
      {controller.displayAcceptedJointInviteModal && <AcceptedJointInviteModal />}
    </div>
  );
}
