import { BalanceCardView } from '@/components/balance-view/balance-card-view';
import { useFeatureWithUserStrategies } from '@/components/feature-flag/hooks/use-feature-with-user-strategies';
import { InfoButtonToolTip } from '@/components/info-button-tool-tip';
import { URL_HMB_TO_NYCB_DEPOSIT_AGREEMENT } from '@/constants/app';
import { FEATURE_FLAG_PLANS_V2 } from '@/constants/features';
import { useGetDepositAccountsQuery } from '@/generated/graphql';
import { isOpenNycbAccount } from '@/helpers';
import { InterestRateNumberFormatter } from '@/i18n/numbers';
import { useCreatePlanDrawerContext } from '@/routes/account/components/create-plan-drawer/hooks/use-create-plan-drawer-context';
import * as React from 'react';
import { useQueryClient } from 'react-query';
import { CardSkeleton, DepositCardCta, LastFour } from './card-skeletons';

function AccountBalance(props: { availableBalanceInCents: number }) {
  return (
    <div className="flex flex-col items-start">
      <h3 className="font-medium leading-none">
        <BalanceCardView hasCents={true} amount={props.availableBalanceInCents / 100} />
      </h3>
    </div>
  );
}

function PlansBalance(props: { totalPlanBalanceInCents: number }) {
  return (
    <div className="flex flex-col items-end justify-between">
      <p className="text-sm font-normal text-black-lighter mt-px">Plans Balance</p>
      <p className="font-medium leading-none">
        <BalanceCardView hasCents={true} amount={props.totalPlanBalanceInCents / 100} />
      </p>
    </div>
  );
}

function EarningThisMonth(props: { apyInterestRate: string }) {
  return (
    <div className="flex flex-col">
      <p className="text-sm font-normal text-black-lighter mb-0.5">Earning This Month</p>
      <p className="font-medium text-lg leading-none text-right">
        {InterestRateNumberFormatter.format(parseFloat(props.apyInterestRate))} APY<sup>1</sup>
      </p>
    </div>
  );
}

function DepositAccountInfo(props: {
  id: string;
  lastFour: string;
  availableBalanceInCents: number;
  totalPlanBalanceInCents: number;
  apyInterestRate: string;
  isPendingJointAccount: boolean;
}) {
  const queryClient = useQueryClient();
  const createPlanDrawerContext = useCreatePlanDrawerContext();
  const plansV2FeatureFlag = useFeatureWithUserStrategies(FEATURE_FLAG_PLANS_V2);

  function onClickNewPlan() {
    createPlanDrawerContext.openCreatePlanDrawer({
      accountId: props.id,
      withFundPlanSteps: true,
      onSuccessCallback: () => {
        queryClient.invalidateQueries('GetDepositAccounts');
      },
    });
  }

  return (
    <div className="flex justify-between flex-col gap-6">
      <div className="flex flex-col gap-1">
        <LastFour lastFour={props.lastFour} />
        <div className="flex justify-between items-start">
          <h3 className="font-medium text-lg leading-none w-min">
            {props.isPendingJointAccount ? 'Joint Deposit Account' : 'Deposit Account'}
          </h3>
          <EarningThisMonth apyInterestRate={props.apyInterestRate} />
        </div>
      </div>
      <div>
        <div className="flex justify-between">
          <div className="flex flex-col">
            <div className="flex items-center">
              <p className="text-sm font-normal text-black-lighter mr-1">Available Balance</p>
              <InfoButtonToolTip
                className="mt-px"
                panelClassName="right-0 left-auto -translate-x-0 lg:right-auto lg:left-1/2 lg:-translate-x-1/2"
              >
                <div className="bg-gray-700 rounded p-4">
                  <p className="text-white font-medium text-sm">
                    Available balance does not include balances in any Plans and the other items listed in the
                    definition of Available Balance in the{' '}
                    <a href={URL_HMB_TO_NYCB_DEPOSIT_AGREEMENT} className="underline" target="_blank" rel="noreferrer">
                      HMBradley Deposit Account Agreement and Disclosures
                    </a>
                    .
                  </p>
                </div>
              </InfoButtonToolTip>
            </div>
            <AccountBalance availableBalanceInCents={props.availableBalanceInCents} />
          </div>
          <PlansBalance totalPlanBalanceInCents={props.totalPlanBalanceInCents} />
        </div>
        {plansV2FeatureFlag.isEnabled && (
          <div className="flex justify-end mt-3">
            <div role="button" onClick={onClickNewPlan} className="text-blue cursor-pointer text-sm">
              + New Plan
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

type OpenedProps = {
  id: string;
  availableBalanceInCents: number;
  totalPlanBalanceInCents: number;
  lastFour: string;
  apyInterestRate: string;
  isPendingJointAccount: boolean;
};

function Opened(props: OpenedProps) {
  return (
    <div className="flex flex-col bg-white rounded-lg col-span-12 lg:col-span-4">
      {props.isPendingJointAccount && (
        <div className="w-full py-2 px-6 bg-yellow-300 rounded-t-lg">
          <p className="text-gray-900 text-sm mb-0.5">Pending - Invitation sent</p>
        </div>
      )}
      <div className="flex flex-col gap-4 flex-1 justify-between p-6">
        <DepositAccountInfo
          id={props.id}
          availableBalanceInCents={props.availableBalanceInCents}
          totalPlanBalanceInCents={props.totalPlanBalanceInCents}
          lastFour={props.lastFour}
          apyInterestRate={props.apyInterestRate}
          isPendingJointAccount={props.isPendingJointAccount}
        />
        <DepositCardCta id={props.id} />
      </div>
    </div>
  );
}

function useNycbDeposit() {
  const getDepositAccountsQuery = useGetDepositAccountsQuery(null, {
    select(data) {
      const individualDepositAccounts =
        data?.depositAccounts?.filter(isOpenNycbAccount).filter((account) => account.accountType === 'INDIVIDUAL') ??
        [];
      return {
        hasIndividualAccount: !!individualDepositAccounts.length,
        individualDepositAccounts,
      };
    },
  });

  return {
    isLoading: getDepositAccountsQuery.isLoading,
    isError: getDepositAccountsQuery.isError,
    hasIndividualAccount: getDepositAccountsQuery.data?.hasIndividualAccount,
    individualDepositAccounts: getDepositAccountsQuery.data?.individualDepositAccounts,
  };
}

export function NycbDeposit() {
  const controller = useNycbDeposit();

  if (controller.isError) {
    return <CardSkeleton isError />;
  }

  if (controller.isLoading) {
    return <CardSkeleton />;
  }

  if (controller.hasIndividualAccount) {
    return (
      <>
        {controller.individualDepositAccounts.map((account) => (
          <Opened
            key={account.id}
            id={account.id}
            availableBalanceInCents={account.depositAccountBalances?.availableBalanceInCents}
            apyInterestRate={account.apy?.apyInterestRate}
            lastFour={account.depositAccountNumber?.lastFour}
            totalPlanBalanceInCents={account.depositAccountBalances?.totalPlansBalance}
            isPendingJointAccount={account.isPendingJointAccount}
          />
        ))}
      </>
    );
  }

  return null;
}
