import { DashboardView } from '@/components/dashboard/dashboard-view';
import { FeatureFlag } from '@/components/feature-flag';
import { FeatureFlagBanner } from '@/components/feature-flag-banner';
import { PageTitle } from '@/components/page-title';
import { WindDownTopBlogPostBanner } from '@/components/wind-down-banners/wind-down-top-blog-post-banner';
import { URL_HMB_TO_THE_ROAD_AHEAD } from '@/constants/app';
import {
  FEATURE_FLAG_WIND_DOWN,
  FEATURE_FLAG_WIND_DOWN_BANNERS,
  FEATURE_FLAG_WIND_DOWN_CREDIT_INVITES,
} from '@/constants/features';
import { useGetClosedDepositAccountsQuery, useGetDepositAccountsQuery } from '@/generated/graphql';
import { isCreditAccount } from '@/helpers';
import { useTrackOccFunnelEvents } from '@/hooks/analytics';
import { useExternalAccounts } from '@/hooks/use-external-accounts';
import { Banner } from '@/routes/account/routes/accounts-overview/components/banner';
import { CardAccountError } from '@/routes/account/routes/accounts-overview/components/card-account-error';
import { CardAccountLoading } from '@/routes/account/routes/accounts-overview/components/card-account-loading';
import { CardClosedDepositAccount } from '@/routes/account/routes/accounts-overview/components/card-closed-deposit-account';
import { CardCreditAccount } from '@/routes/account/routes/accounts-overview/components/card-credit-account';
import { CardCreditInvitation } from '@/routes/account/routes/accounts-overview/components/card-credit-invitation';
import { CashflowSummary } from '@/routes/account/routes/accounts-overview/components/cashflow-summary';
import { CreditInsights } from '@/routes/account/routes/accounts-overview/components/credit-insights';
import { Footnotes } from '@/routes/account/routes/accounts-overview/components/footnotes';
import { RoutinesOverview } from '@/routes/account/routes/accounts-overview/components/routines-overview';
import { httpClient } from '@/transports/http';
import { UseGetDeciderV1OffersResult } from '@/types/mainframe';
import {
  useGetCardV1Cards,
  useGetCardV1CardsBalances,
  useGetCardV1CardsRecurringPayment,
  useGetDeciderV1Offers,
} from '@core/mainframe-react-query';
import { useRouter } from 'next/router';
import * as React from 'react';
import { CardDepositAccount } from '../accounts/components/card-deposit-account';

function useAccountsOverview() {
  const trackOccFunnelEvents = useTrackOccFunnelEvents();

  const getCardV1CardsQuery = useGetCardV1Cards(httpClient, {});
  const creditCard = (getCardV1CardsQuery.data?.data || []).find(isCreditAccount);

  const getDeciderV1OffersQuery = useGetDeciderV1Offers<UseGetDeciderV1OffersResult>(httpClient, {});
  const creditCardOffers = getDeciderV1OffersQuery.data?.creditCardOffers || [];

  const getCardV1CardsBalancesQuery = useGetCardV1CardsBalances(httpClient, {
    params: {
      path: { card_id: creditCard?.id },
    },
    options: {
      enabled: !!creditCard?.id,
    },
  });
  const creditCardBalance = getCardV1CardsBalancesQuery.data;

  const getCardV1CardsRecurringPaymentQuery = useGetCardV1CardsRecurringPayment(httpClient, {
    params: { path: { card_id: creditCard?.id } },
    options: {
      enabled: !!creditCard?.id,
    },
  });
  const creditCardAutopay = getCardV1CardsRecurringPaymentQuery.data?.[0];
  const getClosedDepositAccounts = useGetClosedDepositAccountsQuery();
  const getDepositAccountsQuery = useGetDepositAccountsQuery();
  const depositAccounts = getDepositAccountsQuery.data?.depositAccounts || [];
  const closedDepositAccounts = getClosedDepositAccounts.data?.depositAccounts || [];

  function onViewInvitation() {
    trackOccFunnelEvents({
      cta_id: 'credit-offer-view',
      position: 'occ_page_offer_card',
    });
  }

  return {
    onViewInvitation,
    isLoadingCredit:
      getCardV1CardsQuery.isLoading ||
      getCardV1CardsBalancesQuery.isLoading ||
      getCardV1CardsRecurringPaymentQuery.isLoading ||
      getDeciderV1OffersQuery.isLoading,
    isErrorCredit: getCardV1CardsQuery.isError || getCardV1CardsBalancesQuery.isError,
    creditCard,
    creditCardBalance,
    creditCardAutopay,
    creditCardOffers,
    isLoadingDeposit: getDepositAccountsQuery.isLoading || getClosedDepositAccounts.isLoading,
    isErrorDeposit: getDepositAccountsQuery.isError || getClosedDepositAccounts.isError,
    depositAccounts,
    closedDepositAccounts,
  };
}

export function AccountsOverview() {
  const controller = useAccountsOverview();

  const credit = controller.isLoadingCredit ? (
    <CardAccountLoading />
  ) : controller.isErrorCredit ? (
    <CardAccountError />
  ) : controller.creditCardOffers.length > 0 && (!controller.creditCard || controller.creditCard.isAccountClosed) ? (
    <FeatureFlag invert flag={FEATURE_FLAG_WIND_DOWN_CREDIT_INVITES}>
      <CardCreditInvitation onClick={controller.onViewInvitation} offerId={controller.creditCardOffers[0].offerID} />
    </FeatureFlag>
  ) : (
    <CardCreditAccount
      creditCardAutopay={controller.creditCardAutopay}
      creditCardBalance={controller.creditCardBalance}
      creditCard={controller.creditCard}
    />
  );

  const deposit = controller.isLoadingDeposit ? (
    <CardAccountLoading />
  ) : controller.isErrorDeposit ? (
    <CardAccountError />
  ) : (
    <>
      {controller.depositAccounts.map((account) => (
        <CardDepositAccount key={account.id} account={account} />
      ))}
      {controller.closedDepositAccounts.map((account) => (
        <CardClosedDepositAccount key={account.id} account={account} />
      ))}
    </>
  );

  return (
    <>
      <WindDownTopBlogPostBanner
        title={
          <>
            <span>
              As announced on November 15, 2023, we are winding down our consumer programs. Please refer to our{' '}
            </span>
            <a href={URL_HMB_TO_THE_ROAD_AHEAD} className="text-blue-300 underline" target="_blank" rel="noreferrer">
              blog post
            </a>{' '}
            <span>for important information and dates regarding the wind down.</span>
          </>
        }
      />

      <DashboardView footnotes={<Footnotes />}>
        <PageTitle title="Home Dashboard" />
        <FeatureFlagBanner />
        <div className="pt-6 max-w-screen-lg">
          <FeatureFlag invert flag={FEATURE_FLAG_WIND_DOWN}>
            <Banner />
          </FeatureFlag>
          <div className="flex md:flex-row flex-col flex-wrap gap-4 mb-4 items-stretch">
            {deposit}
            {credit}
          </div>
        </div>
      </DashboardView>
    </>
  );
}
