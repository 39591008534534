import { ACCOUNT_ONE_CLICK_CREDIT_OFFERS_OFFER_ID_PATH } from '@/constants/routes';
import { routeFor } from '@/helpers/router';
import Link from 'next/link';

function CreditInvitationImage() {
  return (
    <svg
      className="min-w-[64px]"
      width="64"
      height="80"
      viewBox="0 0 64 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_410_7463)">
        <rect width="64" height="80" rx="4" fill="#5A46D7" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-7 0H71V9.09544V15.3184V15.3185H-7V15.3184V9.09544V0ZM71 16.2529H-7V23.9029H71V16.2529ZM-7 26.1022H71V31.9353H-7V26.1022ZM71 35.9514H-7V39.9676H71V35.9514ZM-7 45.8006H71V48H-7V45.8006Z"
          fill="#4B36CE"
        />
        <path
          d="M20.0156 32.1599H0.895626C-1.31351 32.1599 -3.10437 33.9508 -3.10437 36.1599V43.0399H20.0156V32.1599Z"
          fill="#3321A6"
        />
        <ellipse cx="21.0359" cy="38.28" rx="14.28" ry="14.28" fill="#AF9CF8" />
        <path
          d="M41 42C41 39.7909 39.2091 38 37 38L-4 38C-6.20914 38 -8 39.7909 -8 42L-8 82L41 82L41 42Z"
          fill="#927EEE"
        />
        <path d="M68 64C68 61.7909 66.2091 60 64 60H18C15.7909 60 14 61.7909 14 64V82H68V64Z" fill="#C9BAFE" />
        <path
          d="M23.416 54.07C23.416 50.5962 26.2321 47.78 29.706 47.78C33.1799 47.78 35.996 50.5962 35.996 54.07L35.996 60.36L29.706 60.36C26.2321 60.36 23.416 57.5439 23.416 54.07Z"
          fill="#E0D7FF"
        />
        <path
          d="M56.5162 51.18C56.5162 47.546 53.5702 44.6 49.9362 44.6C46.3022 44.6 43.3562 47.546 43.3562 51.18L43.3562 57.76L49.9362 57.76C53.5702 57.76 56.5162 54.814 56.5162 51.18Z"
          stroke="#E0D7FF"
          strokeWidth="5.2"
        />
      </g>
      <defs>
        <clipPath id="clip0_410_7463">
          <rect width="64" height="80" rx="4" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export function CardCreditInvitation(props: { offerId: string; onClick: () => void }) {
  return (
    <div className="flex-1 bg-white rounded-lg p-6">
      <div className="flex flex-row items-center mb-4">
        <CreditInvitationImage />
        <div className="ml-5">
          <p className="mb-0.5 text-black text-md leading-6">HMBradley Credit Card Invitation</p>
          <p className="text-sm font-normal text-gray-500">
            Unlock Routine: Automate your payments and keep your credit utilization below 30%.
          </p>
        </div>
      </div>
      <div className="w-full flex justify-end">
        <Link
          onClick={props.onClick}
          href={routeFor(ACCOUNT_ONE_CLICK_CREDIT_OFFERS_OFFER_ID_PATH, {
            path: { offerId: props.offerId },
          })}
        >
          <div className="rounded-md py-2 px-6 bg-gray-700 cursor-pointer">
            <p className="mb-0.5 text-white text-base whitespace-nowrap">View invitation</p>
          </div>
        </Link>
      </div>
    </div>
  );
}
