import { MonthCashFlow } from '@/generated/graphql';
import { isNil } from '@/helpers/is-nil';
import { MonthCashflowPopover } from '@/routes/account/routes/home/components/cashflow-summary/components/cashflow-summary-chart/components/month-cashflow-popover';

function DepositsItem(props: { maxDeposits: number; height: number; monthCashflow: MonthCashFlow }) {
  const itemHeight = (props.monthCashflow.totalDepositsAmount / props.maxDeposits) * props.height;

  return (
    <div className="bg-blue-300 w-3.5 rounded-full relative" style={{ height: `${itemHeight}px` }}>
      {props.monthCashflow.totalDepositsAmount > props.monthCashflow.totalWithdrawalsAmount && (
        <div
          className="bg-gray-950 w-3.5 h-3.5 rounded-full absolute"
          style={{
            bottom: `calc(${(props.monthCashflow.totalNetAmount / props.monthCashflow.totalDepositsAmount) * 100}% - ${
              itemHeight > 10 ? '0.875rem' : '0rem'
            })`,
          }}
        />
      )}
      {props.monthCashflow.totalDepositsAmount === 0 && props.monthCashflow.totalWithdrawalsAmount === 0 && (
        <div className="absolute bg-gray-950 w-3.5 h-3.5 rounded-full -translate-y-1/2 top-0" />
      )}
      {isNil(props.monthCashflow.totalDepositsAmount) && isNil(props.monthCashflow.totalDepositsAmount) && (
        <div className="absolute border-gray-950 border-dotted border-2 bg-white w-3.5 h-3.5 rounded-full -translate-y-1/2 top-0" />
      )}
    </div>
  );
}
export function Deposits(props: {
  maxDeposits: number;
  maxWithdrawals: number;
  height: number;
  previousSixMonthsCashflow: MonthCashFlow[];
}) {
  return (
    <div className="flex items-end justify-between px-4 relative z-10" style={{ height: `${props.height}px` }}>
      {props.previousSixMonthsCashflow.map((monthCashflow, index) => (
        <MonthCashflowPopover key={`deposits-${monthCashflow.date}`} monthCashflow={monthCashflow} left={index > 2}>
          <DepositsItem maxDeposits={props.maxDeposits} monthCashflow={monthCashflow} height={props.height} />
        </MonthCashflowPopover>
      ))}
    </div>
  );
}
