import { HATCH_DEPOSIT_TRANSACTIONS_PATH, NYCB_DEPOSIT_STATEMENTS_PATH } from '@/constants/routes';
import { routeFor } from '@/helpers/router';
import Link from 'next/link';
import { ReactNode } from 'react';

type ClosedCardProps = {
  id?: string;
  title: string;
  children: ReactNode;
};

function DepositCardCta(props: { id?: string }) {
  return (
    <div className="flex gap-2 pt-2 flex-col sm:flex-row lg:flex-col sm:justify-end">
      {props.id && (
        <Link
          href={{
            pathname: routeFor(HATCH_DEPOSIT_TRANSACTIONS_PATH, {
              path: {
                depositAccountId: props.id,
              },
            }),
          }}
          className="border-black-lightest border-3 rounded-md px-5 py-1 text-sm w-full sm:w-fit lg:w-full text-center"
        >
          View Transactions
        </Link>
      )}
      <Link
        href={{
          pathname: routeFor(NYCB_DEPOSIT_STATEMENTS_PATH, {
            path: {
              depositAccountId: props.id,
            },
          }),
        }}
        className="border-black-lightest border-3 rounded-md px-5 py-1 text-sm w-full sm:w-fit lg:w-full text-center"
      >
        View Statements
      </Link>
    </div>
  );
}

export function ClosedCard(props: ClosedCardProps) {
  return (
    <div className="flex flex-col bg-white rounded-lg col-span-12 lg:col-span-4">
      <div className="bg-grey px-4 py-2 rounded-t-lg text-white">This account is closed.</div>
      <div className="flex flex-col gap-2 p-6">
        <div className="pointer-event-none opacity-40">{props.children}</div>
        <p className="text-sm font-normal">Where are my funds?</p>
        <p className="font-normal text-sm">{props.title}</p>
        <DepositCardCta id={props.id} />
      </div>
    </div>
  );
}
