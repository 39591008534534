import { ClosedAccountBanner } from '@/components/wind-down-banners/closed-account-banner';
import { NYCB_DEPOSIT_STATEMENTS_PATH } from '@/constants/routes';
import { DepositAccount, DepositAccountType } from '@/generated/graphql';
import { routeFor } from '@/helpers/router';
import { ArrowIcon } from '@/routes/account/routes/accounts-overview/components/images';
import { useRouter } from 'next/router';

export function CardClosedDepositAccount(props: { account: DepositAccount }) {
  const router = useRouter();

  function pushToStatements() {
    router.push(routeFor(NYCB_DEPOSIT_STATEMENTS_PATH, { path: { depositAccountId: props.account.id } }));
  }

  return (
    <div
      className="basis-1/4 flex-1 flex flex-col bg-white rounded-lg p-2 cursor-pointer min-w-lg md:max-w-[30%]"
      onClick={pushToStatements}
    >
      <ClosedAccountBanner />
      <div className="flex flex-col justify-between gap-4 grow hover:bg-gray-50 p-4 duration-100 rounded-md">
        <div className="flex flex-row items-center justify-between mb-6">
          <p className="text-black">
            {props.account.accountType === DepositAccountType.Joint || props.account.isPendingJointAccount
              ? 'Joint '
              : ''}{' '}
            Deposit - {props.account.depositAccountNumber.lastFour} (Closed)
          </p>
        </div>
        <div className="flex items-end justify-between">
          <div className="">See Statements and 1099s</div>
          <ArrowIcon />
        </div>
      </div>
    </div>
  );
}
