import { MonthCashFlow } from '@/generated/graphql';
import { sortDate } from '@/helpers/sort-functions';
import { DateTime } from 'luxon';

export function getLastSixMonthsCopy() {
  const endDate = DateTime.now();
  const startDate = endDate.minus({ months: 5 });

  const isSameYear = endDate.year === startDate.year;
  const startDateText = `${startDate.toFormat('MMM')}${isSameYear ? '' : ', ' + startDate.year}`;
  const endDateText = `${endDate.toFormat('MMM')}, ${endDate.year}`;

  return `${startDateText} - ${endDateText}`;
}

// Populates cashflow data with empty months data + sorts by cashflow month
export function generatePreviousSixMonthsCashFlow(monthlyCashFlow: MonthCashFlow[]) {
  const sortedMonthlyCashFlow = [...monthlyCashFlow].sort((a: MonthCashFlow, b: MonthCashFlow) =>
    sortDate(b.date, a.date),
  );

  const sixElementsArray = Array.from(Array(6).keys());
  const previousSixMonthsCashFlowWithEmptyMonths = sixElementsArray.reduce((acc, item, index) => {
    if (sortedMonthlyCashFlow[index]) {
      return [...acc, sortedMonthlyCashFlow[index]];
    }
    const emptyMonthItem = {
      date: acc[index - 1]
        ? DateTime.fromISO(acc[index - 1].date)
            .minus({ months: 1 })
            .toFormat('yyyy-MM-dd')
        : DateTime.now().toFormat('yyyy-MM-dd'),
    } as MonthCashFlow;
    return [...acc, emptyMonthItem];
  }, []);

  return previousSixMonthsCashFlowWithEmptyMonths.sort((a: MonthCashFlow, b: MonthCashFlow) =>
    sortDate(a.date, b.date),
  );
}
