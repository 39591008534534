import { ROUTINE_DRAFTS_STORAGE_KEY } from '@/constants/app';
import { getLocalStorageItem, setLocalStorageItem } from '@/storages/local';

export function getDrafts() {
  return getLocalStorageItem<string[]>(ROUTINE_DRAFTS_STORAGE_KEY) ?? [];
}

export function setDraft(templateId: string) {
  const drafts = getLocalStorageItem<string[]>(ROUTINE_DRAFTS_STORAGE_KEY);
  if (!drafts) {
    setLocalStorageItem(ROUTINE_DRAFTS_STORAGE_KEY, [templateId]);
  } else {
    const draft = drafts.find((template) => template == templateId);
    if (!draft) {
      setLocalStorageItem(ROUTINE_DRAFTS_STORAGE_KEY, [...drafts, templateId]);
    }
  }
}

export function removeDraft(templateId: string) {
  const drafts = getLocalStorageItem<string[]>(ROUTINE_DRAFTS_STORAGE_KEY);
  if (drafts) {
    const draftsFiltered = drafts.filter((template) => template !== templateId);
    setLocalStorageItem(ROUTINE_DRAFTS_STORAGE_KEY, draftsFiltered);
  }
}
