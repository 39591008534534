import { BalanceCardView } from '@/components/balance-view/balance-card-view';
import { CreditCard, CREDIT_CARD_TYPES } from '@/components/credit';
import { FULLSTORY_EXCLUDE } from '@/constants';
import { creditCardColors } from '@/constants/credit-cards';
import {
  ACCOUNT_ONE_CLICK_CREDIT_OFFERS_OFFER_ID_PATH,
  ACTIVATE_CARD_PATH,
  CREDIT_PAYMENT_PATH,
  PATH_ACCOUNT_CREDIT_DETAILS,
} from '@/constants/routes';
import { CreditAccountContext } from '@/contexts/credit-account-context';
import { isCreditAccount } from '@/helpers';
import { AutoPayAmount } from '@/helpers/auto-pay';
import { routeFor } from '@/helpers/router';
import { useActiveCreditAccount } from '@/hooks/use-active-account';
import { useAuthenticatedSessionContext } from '@/hooks/use-session-context';
import { UTCDateShortFormatter } from '@/i18n/datetime';
import { OneTimeAdditionPaymentWithSidebar } from '@/routes/account/routes/credit/routes/[cardId]/payment/components/card-payment/components/one-time-addition-payment/one-time-addition-payment-with-sidebar';
import { httpClient } from '@/transports/http';
import { UseGetDeciderV1OffersResult } from '@/types/mainframe';
import {
  useGetCardV1Cards,
  useGetCardV1CardsBalances,
  useGetCardV1CardsRecurringPayment,
  useGetDeciderV1Offers,
  useGetRegistrarV3EstimatedRewards,
} from '@core/mainframe-react-query';
import { ArrowPathIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import Link from 'next/link';
import React from 'react';
import { CardSkeleton, LastFour } from './card-skeletons';

const PAYMENT_LABELS = {
  [AutoPayAmount.REMAINING_STATEMENT_BALANCE]: 'Paying Remaining Statement Balance',
  [AutoPayAmount.MINIMUM_PAYMENT]: 'Paying Minimum Payment Due',
};

function CreditCardCta(props: { id?: string }) {
  if (!props.id) {
    return null;
  }

  return (
    <div className="flex gap-2 self-end">
      <Link
        href={routeFor(CREDIT_PAYMENT_PATH, {
          path: {
            cardId: props.id,
          },
        })}
        passHref
        className={clsx('border-black-lightest border-3 rounded-md px-2 py-1 text-sm text-clip text-center', {
          'opacity-40 pointer-events-none': !props.id,
        })}
      >
        Make a Payment
      </Link>

      <Link
        href={{
          pathname: routeFor(PATH_ACCOUNT_CREDIT_DETAILS, {
            path: {
              cardId: props.id,
            },
          }),
        }}
        passHref
        className={clsx('border-black-lightest border-3 rounded-md px-2 py-1 text-sm text-clip text-center', {
          'opacity-40 pointer-events-none': !props.id,
        })}
      >
        View Account
      </Link>
    </div>
  );
}

function OneTimeAdditionPaymentWithContext(props: {
  onCloseOneTimePayment: () => void;
  onOpenOneTimePayment: () => void;
  isOpenOneTimePayment: boolean;
  id: string;
}) {
  const creditContext = useActiveCreditAccount({ id: props.id });

  if (creditContext.isLoading || creditContext.isError) return null;

  return (
    <CreditAccountContext.Provider value={creditContext}>
      <OneTimeAdditionPaymentWithSidebar
        onDrawerClose={props.onCloseOneTimePayment}
        isOpen={props.isOpenOneTimePayment}
        title="MAKE A PAYMENT"
      />
      <div className="flex gap-2 self-end">
        <button
          onClick={props.onOpenOneTimePayment}
          className="border-black-lightest border-3 rounded-md px-2 py-1 text-sm text-clip text-center"
        >
          Make a payment
        </button>
      </div>
    </CreditAccountContext.Provider>
  );
}

function ClosedCreditCardCta(props: { id: string }) {
  const [isOpenOneTimePayment, setIsOpenOneTimePayment] = React.useState(false);

  function onCloseOneTimePayment() {
    setIsOpenOneTimePayment(false);
  }

  function onOpenOneTimePayment() {
    setIsOpenOneTimePayment(true);
  }

  if (!props.id) {
    return null;
  }

  return (
    <OneTimeAdditionPaymentWithContext
      onCloseOneTimePayment={onCloseOneTimePayment}
      onOpenOneTimePayment={onOpenOneTimePayment}
      isOpenOneTimePayment={isOpenOneTimePayment}
      id={props.id}
    />
  );
}

function StatementOrMinPaymentSection(props: {
  autoPayOn: boolean;
  hasPaymentDue: boolean;
  hasAutoPay: boolean;
  remainingStatementBalance?: string;
  minimumDue?: string;
  paymentType: string;
}) {
  return (
    <div className="flex justify-between gap-1">
      {props.hasPaymentDue && props.hasAutoPay && (
        <>
          {props.autoPayOn ? (
            <div>
              <p className="text-sm font-normal text-black-lighter truncate">{PAYMENT_LABELS[props.paymentType]}</p>
              <div className="flex gap-2 items-center">
                <h3 className={clsx('font-medium', FULLSTORY_EXCLUDE)}>
                  <BalanceCardView
                    hasCents={true}
                    amount={
                      props.paymentType === AutoPayAmount.REMAINING_STATEMENT_BALANCE
                        ? props.remainingStatementBalance
                        : props.minimumDue
                    }
                  />
                </h3>
                <div className="flex items-center gap-2 rounded-xl px-2 py-1 bg-green-400 mt-1">
                  <ArrowPathIcon className="w-3 h-3" />
                  <h3 className="text-tiny">Auto-Pay</h3>
                </div>
              </div>
            </div>
          ) : (
            <div>
              <p className="text-sm font-normal text-black-lighter truncate">Remaining Statement Balance</p>
              <h3 className={clsx('font-medium', FULLSTORY_EXCLUDE)}>
                <BalanceCardView hasCents={true} amount={props.remainingStatementBalance} />
              </h3>
            </div>
          )}
        </>
      )}
    </div>
  );
}

function InvitedImg() {
  const clipPath0 = React.useId();

  return (
    <svg className="rounded-t-lg" viewBox="0 0 344 120" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath={`url(#${clipPath0})`}>
        <rect width="344" height="120" fill="#5A46D7" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M151 0H347V21.0332V35.4239V35.4241H151V35.4239V21.0332V0ZM347 37.585H151V55.2755H347V37.585ZM151 60.3613H347V73.8504H151V60.3613ZM347 83.1377H151V92.4252H347V83.1377ZM151 105.914H347V111H151V105.914Z"
          fill="#4B36CE"
        />
        <path d="M139 20H81C75.4772 20 71 24.4772 71 30V52H139V20Z" fill="#3321A6" />
        <circle cx="142" cy="38" r="42" fill="#AF9CF8" />
        <path
          d="M200 48C200 42.4772 195.523 38 190 38L66 38C60.4771 38 56 42.4772 56 48L56 146L200 146L200 48Z"
          fill="#927EEE"
        />
        <path
          d="M149 73.5C149 63.2827 157.283 55 167.5 55C177.717 55 186 63.2827 186 73.5L186 92L167.5 92C157.283 92 149 83.7173 149 73.5Z"
          fill="#E0D7FF"
        />
        <path
          d="M247.5 65C247.5 53.6782 238.322 44.5 227 44.5C215.678 44.5 206.5 53.6782 206.5 65L206.5 85.5L227 85.5C238.322 85.5 247.5 76.3218 247.5 65Z"
          stroke="#E0D7FF"
          strokeWidth="13"
        />
        <path
          d="M280 102C280 96.4772 275.523 92 270 92H132C126.477 92 122 96.4772 122 102V146H280V102Z"
          fill="#C9BAFE"
        />
      </g>
      <defs>
        <clipPath id={clipPath0}>
          <rect width="344" height="120" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

function Invited(props: { offerId: string }) {
  return (
    <div className="flex justify-between bg-white flex-col rounded-xl col-span-12 lg:col-span-4">
      <InvitedImg />
      <div className="flex-1 justify-between flex flex-col gap-4 p-6 rounded-b-lg">
        <div className="flex flex-col gap-1">
          <p className="text-sm text-gray-500 font-normal">You’re invited!</p>
          <div className="flex justify-between">
            <h3 className="font-medium text-lg leading-none">Credit Card</h3>
            <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M5 9V5C5 2.79086 6.79086 1 9 1C11.2091 1 13 2.79086 13 5M9 13V15M3 19H15C16.1046 19 17 18.1046 17 17V11C17 9.89543 16.1046 9 15 9H3C1.89543 9 1 9.89543 1 11V17C1 18.1046 1.89543 19 3 19Z"
                stroke="#0B1012"
                strokeWidth="2"
                strokeLinecap="round"
              />
            </svg>
          </div>
          <p className="font-normal text-base mt-1.5">
            Unlock up to 4.70% APY1 on your deposit account today with an HMBradley Credit Card.
          </p>
        </div>
        <Link
          href={routeFor(ACCOUNT_ONE_CLICK_CREDIT_OFFERS_OFFER_ID_PATH, {
            path: { offerId: props.offerId },
          })}
          passHref
          className="bg-purple-400 rounded-md py-2.5 text-base text-white text-center hover:bg-purple-300"
        >
          Accept invitation now
        </Link>
      </div>
    </div>
  );
}

function InactiveHeader(props: { color: string }) {
  return (
    <div className="flex flex-col">
      <div className="flex flex-col flex-1 gap-2">
        <p className="text-sm font-normal text-black-lighter">Attention required</p>
        <div className="flex items-center gap-4">
          <CreditCard className="w-6" {...creditCardColors[props.color]} type={CREDIT_CARD_TYPES['credit']} />
          <h3 className="font-medium text-lg leading-none">Credit Card</h3>
        </div>
      </div>
    </div>
  );
}

function Inactive(props: { color: string }) {
  return (
    <div className="flex flex-col justify-between p-6 bg-white rounded-lg col-span-12 lg:col-span-4">
      <div className="flex flex-col gap-3">
        <InactiveHeader color={props.color} />
        <p className="font-normal leading-6">Please activate your card once you’ve received it in the mail.</p>
      </div>
      <Link
        href={{
          pathname: ACTIVATE_CARD_PATH,
        }}
        passHref
        className="w-full rounded-lg py-2.5 text-center bg-black text-base text-white font-medium hover:bg-black-lighter"
      >
        Activate Card
      </Link>
    </div>
  );
}

type OpenedProps = {
  id: string;
} & CardContentProps;

type CardContentProps = {
  lastFour: string;
  currentBalance: string;
  availableBalance?: string;
  firstName: string;
  lastName: string;
  hasPaymentDue: boolean;
  nextPaymentDueDate: string;
  color: string;
  hasAutoPay: boolean;
  autoPayOn: boolean;
  remainingStatementBalance?: string;
  minimumDue?: string;
  paymentType: string;
};

function CardContent(props: CardContentProps) {
  return (
    <div className="flex flex-col gap-3">
      <div className="flex flex-col gap-1">
        <LastFour lastFour={props.lastFour} />
        <div className="flex justify-between">
          <div className="flex gap-2 items-start">
            <CreditCard
              className="w-9"
              firstName={props.firstName}
              lastName={props.lastName}
              {...creditCardColors[props.color]}
              type={CREDIT_CARD_TYPES['credit']}
            />
            <h3 className="font-medium text-lg">
              Credit
              <br />
              Card
            </h3>
          </div>
          <div className="flex flex-col items-end">
            <p className="text-sm font-normal text-black-lighter truncate">Current Balance</p>
            <h3 className={clsx('font-medium text-lg', FULLSTORY_EXCLUDE)}>
              <BalanceCardView hasCents={true} amount={props.currentBalance} />
            </h3>
          </div>
        </div>
      </div>
      {/*  */}
      <div className="flex justify-between">
        <div className="flex flex-col justify-between">
          <p className="text-sm font-normal text-black-lighter truncate">Payment Due Date</p>
          {props.hasPaymentDue ? (
            <p>{UTCDateShortFormatter.format(new Date(props.nextPaymentDueDate))}</p>
          ) : (
            <p>No payment due</p>
          )}
        </div>

        {props.availableBalance && (
          <div className="flex flex-col items-end">
            <p className="text-sm font-normal text-black-lighter">Available Credit</p>
            <h3 className={clsx('font-medium', FULLSTORY_EXCLUDE)}>
              <BalanceCardView hasCents={true} amount={props.availableBalance} />
            </h3>
          </div>
        )}
      </div>
      <StatementOrMinPaymentSection
        hasPaymentDue={props.hasPaymentDue}
        hasAutoPay={props.hasAutoPay}
        autoPayOn={props.autoPayOn}
        minimumDue={props.minimumDue}
        remainingStatementBalance={props.remainingStatementBalance}
        paymentType={props.paymentType}
      />
    </div>
  );
}

function Opened(props: OpenedProps) {
  return (
    <div className="flex flex-col justify-between bg-white col-span-12 lg:col-span-4 h-full p-6 gap-6 rounded-xl">
      <CardContent
        color={props.color}
        lastName={props.lastName}
        firstName={props.firstName}
        currentBalance={props.currentBalance}
        lastFour={props.lastFour}
        availableBalance={props.availableBalance}
        nextPaymentDueDate={props.nextPaymentDueDate}
        hasPaymentDue={props.hasPaymentDue}
        autoPayOn={props.autoPayOn}
        hasAutoPay={props.hasAutoPay}
        paymentType={props.paymentType}
        remainingStatementBalance={props.remainingStatementBalance}
        minimumDue={props.minimumDue}
      />
      <CreditCardCta id={props.id} />
    </div>
  );
}

function Closed(props: OpenedProps) {
  return (
    <div className="flex flex-col bg-white col-span-12 lg:col-span-4 h-full gap-6 rounded-lg">
      <div className="font-normal text-white px-6 py-3 bg-gray-500 rounded-t-lg">
        This account is closed. Please make any final payments to your balance.
      </div>
      <div className="flex flex-col justify-between h-full bg-white px-6 pb-6 gap-6">
        <CardContent
          color={props.color}
          lastName={props.lastName}
          firstName={props.firstName}
          currentBalance={props.currentBalance}
          lastFour={props.lastFour}
          nextPaymentDueDate={props.nextPaymentDueDate}
          hasPaymentDue={props.hasPaymentDue}
          autoPayOn={props.autoPayOn}
          hasAutoPay={props.hasAutoPay}
          paymentType={props.paymentType}
          remainingStatementBalance={props.remainingStatementBalance}
          minimumDue={props.minimumDue}
        />
        <ClosedCreditCardCta id={props.id} />
      </div>
    </div>
  );
}

function useCredit() {
  const session = useAuthenticatedSessionContext();
  const getRegistrarV3EstimatedRewardsQuery = useGetRegistrarV3EstimatedRewards(httpClient, {});
  const offersQuery = useGetDeciderV1Offers<UseGetDeciderV1OffersResult>(httpClient, {});

  const getCardV1CardsQuery = useGetCardV1Cards(httpClient, {
    options: {
      select(data) {
        const creditCard = data.data?.find(isCreditAccount);
        return {
          creditCard,
        };
      },
    },
  });

  const getCardV1CardsBalances = useGetCardV1CardsBalances(httpClient, {
    params: {
      path: { card_id: getCardV1CardsQuery.data?.creditCard?.id },
    },
    options: {
      enabled: Boolean(getCardV1CardsQuery.data?.creditCard?.isActive),
      select(data) {
        return {
          balances: data,
          hasPaymentDue: data.nextPaymentDueDate && parseFloat(data.remainingStatementBalance) > 0,
        };
      },
    },
  });

  const getCardV1CardsRecurringPaymentQuery = useGetCardV1CardsRecurringPayment(httpClient, {
    params: { path: { card_id: getCardV1CardsQuery.data?.creditCard?.id } },
    options: {
      enabled: !!getCardV1CardsQuery.data?.creditCard?.isActive,
      select(data) {
        const autoPayOn = data && Array.isArray(data) && data[0] && !data[0].archivedDate;
        return {
          autoPayOn,
          paymentType: (autoPayOn && data[0].paymentType) ?? '',
          hasAutoPay: !!data[0]?.paymentID,
        };
      },
    },
  });

  return {
    id: getCardV1CardsQuery.data?.creditCard?.id,
    hasCreditAccount: Boolean(getCardV1CardsQuery.data?.creditCard),
    isClosedWithBalance:
      getCardV1CardsQuery.data?.creditCard?.isAccountClosed &&
      Number(getCardV1CardsBalances.data?.balances?.remainingStatementBalance) > 0,
    isClosedWithNegativeBalance:
      getCardV1CardsQuery.data?.creditCard?.isAccountClosed &&
      0 >= Number(getCardV1CardsBalances.data?.balances?.remainingStatementBalance),
    creditCardOffers: offersQuery.data?.creditCardOffers,
    isActive: Boolean(getCardV1CardsQuery.data?.creditCard?.isActive),
    lastFour: getCardV1CardsQuery.data?.creditCard?.lastFour,
    hasPaymentDue: Boolean(getCardV1CardsBalances.data?.hasPaymentDue),
    currentBalance: getCardV1CardsBalances.data?.balances?.currentBalance,
    availableBalance: getCardV1CardsBalances.data?.balances?.availableBalance,
    nextPaymentDueDate: getCardV1CardsBalances.data?.balances?.nextPaymentDueDate,
    remainingStatementBalance: getCardV1CardsBalances.data?.balances?.remainingStatementBalance,
    minimumDue: getCardV1CardsBalances.data?.balances?.minimumDue,
    creditEstimatedRewards: getRegistrarV3EstimatedRewardsQuery.data?.creditEstimatedRewards ?? '0',
    firstName: session.user.nameFirst,
    lastName: session.user.nameLast,
    color: getCardV1CardsQuery.data?.creditCard?.color,
    autoPayOn: Boolean(getCardV1CardsRecurringPaymentQuery.data?.autoPayOn),
    hasAutoPay: Boolean(getCardV1CardsRecurringPaymentQuery.data?.hasAutoPay),
    paymentType: getCardV1CardsRecurringPaymentQuery.data?.paymentType,
    isError:
      offersQuery.isError ||
      getCardV1CardsQuery.isError ||
      getCardV1CardsBalances.isError ||
      getCardV1CardsRecurringPaymentQuery.isError,
    isLoading:
      offersQuery.isLoading ||
      getCardV1CardsQuery.isLoading ||
      getCardV1CardsBalances.isLoading ||
      getRegistrarV3EstimatedRewardsQuery.isLoading ||
      getCardV1CardsRecurringPaymentQuery.isLoading,
  };
}

export function Credit() {
  const controller = useCredit();

  if (controller.isError) {
    return (
      <CardSkeleton isError>
        <CreditCardCta id={controller.id} />
      </CardSkeleton>
    );
  }

  if (controller.isLoading) {
    return (
      <CardSkeleton>
        <CreditCardCta id={controller.id} />
      </CardSkeleton>
    );
  }

  if (controller.isClosedWithBalance) {
    return (
      <Closed
        id={controller.id}
        currentBalance={controller.currentBalance}
        availableBalance={controller.availableBalance}
        hasPaymentDue={controller.hasPaymentDue}
        nextPaymentDueDate={controller.nextPaymentDueDate}
        firstName={controller.firstName}
        lastName={controller.lastName}
        lastFour={controller.lastFour}
        color={controller.color}
        hasAutoPay={controller.hasAutoPay}
        autoPayOn={controller.autoPayOn}
        remainingStatementBalance={controller.remainingStatementBalance}
        minimumDue={controller.minimumDue}
        paymentType={controller.paymentType}
      />
    );
  }

  if (controller.hasCreditAccount && !controller.isActive) {
    return <Inactive color={controller.color} />;
  }

  if (controller.hasCreditAccount && !controller.isClosedWithNegativeBalance) {
    return (
      <Opened
        id={controller.id}
        currentBalance={controller.currentBalance}
        availableBalance={controller.availableBalance}
        hasPaymentDue={controller.hasPaymentDue}
        nextPaymentDueDate={controller.nextPaymentDueDate}
        firstName={controller.firstName}
        lastName={controller.lastName}
        lastFour={controller.lastFour}
        color={controller.color}
        hasAutoPay={controller.hasAutoPay}
        autoPayOn={controller.autoPayOn}
        remainingStatementBalance={controller.remainingStatementBalance}
        minimumDue={controller.minimumDue}
        paymentType={controller.paymentType}
      />
    );
  }

  if (controller.creditCardOffers?.length > 0) {
    return <Invited offerId={controller.creditCardOffers?.[0]?.offerID} />;
  }

  return null;
}
