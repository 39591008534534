import { FINANCIAL_INSIGHTS_PATH } from '@/constants/routes';
import { formatPercent } from '@/helpers/to-percent';
import { useGetOccConsents } from '@/hooks/use-get-occ-consents';
import { UTCDateLongFormatter } from '@/i18n/datetime';
import { httpClient } from '@/transports/http';
import { useGetDeciderV1ExperianCreditReport, useGetDeciderV1ExperianCreditScore } from '@core/mainframe-react-query';
import { CardSkeleton } from './card-skeletons';

import Link from 'next/link';
import React from 'react';

function CreditInsideCardCta() {
  return (
    <div className="flex gap-2 self-end">
      <Link
        href={FINANCIAL_INSIGHTS_PATH}
        passHref
        className="border-black-lightest border-3 rounded-md px-2 py-1 text-sm text-clip text-center"
      >
        View all Credit Insights
      </Link>
    </div>
  );
}

function CreditInsideInactiveImg() {
  const clipPath0 = React.useId();
  const filter0 = React.useId();

  return (
    <svg className="rounded-t-lg" viewBox="0 0 344 120" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath={`url(#${clipPath0})`}>
        <rect width="344" height="120" fill="#29353B" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M200 -4.17944H344V19.4546V35.6252H200V19.4546V-4.17944ZM344 38.0527H200V57.931H344V38.0527ZM200 63.646H344V78.8032H200V63.646ZM344 89.2397H200V99.6758H344V89.2397ZM200 114.833H344V120.548H200V114.833Z"
          fill="#3F4E56"
        />
        <circle cx="198" cy="75" r="75" fill="#1A2428" />
        <g filter={`url(#${filter0})`}>
          <path
            d="M214.145 114.032H214.159C214.15 113.901 214.145 113.768 214.145 113.635C214.145 111.658 215.125 109.907 216.632 108.824C227.619 102.515 235 90.7969 235 77.3836C235 57.2895 218.435 41 198 41C177.565 41 161 57.2895 161 77.3836C161 91.0153 168.624 102.896 179.907 109.128C181.49 110.202 182.527 111.998 182.527 114.032C182.527 114.067 182.527 114.102 182.526 114.137C182.526 114.175 182.525 114.213 182.523 114.25C182.521 114.31 182.518 114.369 182.514 114.429H182.527V131.785H182.531C182.573 133.714 182.979 135.619 183.731 137.403C184.525 139.289 185.69 141.003 187.158 142.447C188.626 143.89 190.368 145.035 192.286 145.817C194.205 146.598 196.26 147 198.336 147C200.413 147 202.468 146.598 204.386 145.817C206.304 145.035 208.047 143.89 209.515 142.447C210.983 141.003 212.148 139.289 212.942 137.403C213.694 135.619 214.1 133.714 214.142 131.785H214.145V114.032Z"
            fill="#E8E1FF"
          />
        </g>
        <circle cx="206" cy="72" r="19" fill="#C9BAFE" />
        <path d="M97.2581 121.38L64.6772 121.38L64.6772 88.8934L97.2581 121.38Z" fill="#C9BAFE" />
        <path d="M31.415 55.7468L-1.16584 55.7468L-1.16584 23.2606L31.415 55.7468Z" fill="#3F4E56" />
        <path d="M-1.99995 121.38L-1.99995 88.8933L31.3386 88.8934L-1.99995 121.38Z" fill="#0B1012" />
        <path d="M31.3387 121.38L31.3387 88.8933L64.6772 88.8934L31.3387 121.38Z" fill="#1A2428" />
        <path d="M97.2581 88.8934L64.6772 88.8934L64.6772 55.6517L97.2581 88.8934Z" fill="#1A2428" />
        <path d="M64.6772 88.8934L31.3387 88.8934L31.3387 55.6517L64.6772 88.8934Z" fill="#C9BAFE" />
        <path d="M311 23.2415L344.339 23.2415L344.339 56.4832L311 23.2415Z" fill="#C9BAFE" />
        <path d="M311 23.2417L344.339 23.2417L344.339 -10L311 23.2417Z" fill="#29353B" />
        <rect x="48.0364" y="10.7878" width="33.3576" height="33.2607" rx="16.6303" fill="#C9BAFE" />
      </g>
      <defs>
        <filter
          id="filter0_d_7310_14245"
          x="137"
          y="21"
          width="122"
          height="154"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="12" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_7310_14245" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_7310_14245" result="shape" />
        </filter>
        <clipPath id={clipPath0}>
          <rect width="344" height="120" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

function CreditInsideInactive() {
  return (
    <div className="flex justify-between bg-white flex-col rounded-xl col-span-12 lg:col-span-4">
      <CreditInsideInactiveImg />
      <div className="flex-1 justify-between flex flex-col gap-4 p-6 rounded-b-lg">
        <div className="flex flex-col gap-1">
          <p className="text-sm text-gray-500 font-normal">Stay updated</p>
          <h3 className="font-medium text-lg leading-none">Credit Insights</h3>
          <p className="font-normal text-base mt-1.5">
            Understand the key factors that impact your creditworthiness, like your debt-to-income ratio, credit score,
            and credit utilization.
          </p>
        </div>
        <Link
          href={FINANCIAL_INSIGHTS_PATH}
          passHref
          className="bg-gray-700 rounded-md py-2.5 text-base text-white text-center hover:bg-black-lighter"
        >
          See details
        </Link>
      </div>
    </div>
  );
}

type CreditInsightsCardContentProps = {
  debtToIncomeRatio: string;
  orderedDate: string;
  creditScore: string;
  creditUtilization: string;
};

function CreditInsightsCard(props: CreditInsightsCardContentProps) {
  return (
    <div className="flex flex-col justify-between bg-white col-span-12 lg:col-span-4 h-full p-6 gap-6 rounded-xl">
      <div className="flex flex-col gap-1">
        <p className="text-sm font-normal text-black-lighter truncate">
          Last updated {UTCDateLongFormatter.format(new Date(props.orderedDate))}
        </p>
        <h3 className="font-medium text-lg mb-4">Credit Insights</h3>
        <div className="flex flex-col gap-3">
          <div className="flex justify-between items-center">
            <p className="text-sm font-normal text-black-lighter truncate">Credit score</p>
            <h3 className="font-medium text-base">{props.creditScore}</h3>
          </div>
          <div className="flex justify-between items-center">
            <p className="text-sm font-normal text-black-lighter truncate">Debt-to-income ratio</p>
            <h3 className="font-medium text-base"> {formatPercent(parseInt(props.debtToIncomeRatio))}</h3>
          </div>
          <div className="flex justify-between items-center">
            <p className="text-sm font-normal text-black-lighter truncate">Credit utilization</p>
            <h3 className="font-medium text-base"> {formatPercent(parseFloat(props.creditUtilization))}</h3>
          </div>
        </div>
      </div>

      <CreditInsideCardCta />
    </div>
  );
}

function useCreditScore() {
  const consents = useGetOccConsents();
  const creditScoreQuery = useGetDeciderV1ExperianCreditScore(httpClient, {
    options: {
      enabled: !consents.isLoading && consents.data?.isOptedIn,
    },
  });

  const creditReportQuery = useGetDeciderV1ExperianCreditReport(httpClient, {
    options: {
      enabled: !consents.isLoading && consents.data?.isOptedIn,
    },
  });

  return {
    consents,
    creditScoreQuery,
    debtToIncomeRatio: creditReportQuery.data?.dti,
    creditUtilization: creditReportQuery.data?.creditUtilization,
    creditScore: creditScoreQuery.data?.score,
    orderedDate: creditScoreQuery.data?.orderedDate,
    isOptedIn: consents.data?.isOptedIn,
    isLoading: consents.isLoading || creditScoreQuery.isLoading || creditReportQuery.isLoading,
    isError: consents.isError || creditScoreQuery.isError || creditReportQuery.isError,
  };
}

export function CreditInsights() {
  const controller = useCreditScore();

  if (controller.isError) {
    return (
      <CardSkeleton isError>
        <CreditInsideCardCta />
      </CardSkeleton>
    );
  }

  if (controller.isLoading) {
    return (
      <CardSkeleton>
        <CreditInsideCardCta />
      </CardSkeleton>
    );
  }

  if (controller.isOptedIn) {
    return (
      <CreditInsightsCard
        debtToIncomeRatio={controller.debtToIncomeRatio}
        creditScore={controller.creditScore}
        creditUtilization={controller.creditUtilization}
        orderedDate={controller.orderedDate}
      />
    );
  }

  return <CreditInsideInactive />;
}
