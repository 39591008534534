import { CurrencyFormatter } from '@/i18n/numbers';

type UseBalanceProps = {
  className?: string;
  testId?: string;
  amount: string | number;
  // NOTE: amount to scale down (percent) with each added figure (0–1)
  // A good default would be: { autoScale: 0.1 }
  autoScale?: number;
};

export function useBalanceView(props: UseBalanceProps) {
  const { autoScale = 0 } = props;
  // NOTE: this automatically scales the amount based on length
  // excluding cents assuming largest number is 6 figures
  const { length } = parseInt(props.amount.toString(), 10).toString();
  const scale = Math.max(1 - (length - 1) * autoScale, 0.5);
  const autoSize = `${scale}em`;

  const [numberWithCurrency, decimalNumber] = CurrencyFormatter.format(
    typeof props.amount === 'number' ? props.amount : parseFloat(props.amount),
  ).split('.');
  const currencySymbol = numberWithCurrency.slice(0, 1);
  const integer = numberWithCurrency.slice(1);
  return { currencySymbol, scale, autoSize, decimalNumber, integer };
}
