import { isIndividualAccount } from '@/helpers';
import { useHasDepositAccount } from '@/hooks/use-has-deposit_account';
import { useSwitchToNycb } from '@/hooks/use-nycb-active-accounts';
import { httpClient } from '@/transports/http';
import { useGetLedgerV4Accounts, useGetRegistrarV3EstimatedRewards } from '@core/mainframe-react-query';
import { CardSkeleton, LastFour } from './card-skeletons';
import { ClosedCard } from './closed-card';

function MinimalDepositAccountInfo(props: { accountNumberLastFour: string; accountBalance: string }) {
  return (
    <div className="flex justify-between">
      <div className="flex flex-1 flex-col gap-1">
        <LastFour lastFour={props.accountNumberLastFour} isHatch />
        <div className="flex flex-row justify-between gap-2">
          <h3 className="font-medium text-black-inactive text-lg leading-none w-min">Deposit Account</h3>
          <div className="text-black-inactive flex flex-col gap-3 items-end">
            <p className="font-medium text-sm leading-none">Locked</p>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
              <rect x="4.5" y="12" width="15" height="9" rx="1.5" fill="#a8a8a8" />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.74611 6.9028C9.27015 7.43825 9 8.19461 9 9V12H6V9C6 7.55539 6.47985 6.06175 7.50389 4.9097C8.55247 3.73005 10.093 3 12 3C13.907 3 15.4475 3.73005 16.4961 4.9097C17.5202 6.06175 18 7.55539 18 9V12H15V9C15 8.19461 14.7298 7.43825 14.2539 6.9028C13.8025 6.39495 13.093 6 12 6C10.907 6 10.1975 6.39495 9.74611 6.9028Z"
                fill="#a8a8a8"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
}

function useDeposit() {
  const depositAccounts = useHasDepositAccount();
  const controller = useSwitchToNycb();
  const getLedgerV4AccountsQuery = useGetLedgerV4Accounts(httpClient, {
    options: {
      enabled: depositAccounts.hasDepositAccount,
      select(data) {
        const individualAccount = data.data?.find(isIndividualAccount);
        return {
          individualAccount,
        };
      },
    },
  });

  const getRegistrarV3EstimatedRewardsQuery = useGetRegistrarV3EstimatedRewards(httpClient, {});

  return {
    id: getLedgerV4AccountsQuery.data?.individualAccount?.id,
    accountBalance: getLedgerV4AccountsQuery.data?.individualAccount?.accountBalance,
    accountNumberLastFour: getLedgerV4AccountsQuery.data?.individualAccount?.accountNumberLastFour,
    showClosedHatchContent: controller.showClosedHatchContent,
    isLoading:
      getLedgerV4AccountsQuery.isLoading || getRegistrarV3EstimatedRewardsQuery.isLoading || controller.isLoading,
    isError: getLedgerV4AccountsQuery.isError || controller.isError,
  };
}

export function Deposit() {
  const controller = useDeposit();

  if (controller.isError) {
    return <CardSkeleton isError />;
  }

  if (controller.isLoading) {
    return <CardSkeleton />;
  }

  if (controller.showClosedHatchContent) {
    return (
      <ClosedCard
        id={controller.id}
        key={controller.accountNumberLastFour}
        title="If you did not transfer your funds to a linked external account prior to 10/31/22, we will mail a check for your remaining balance to your address on file."
      >
        <MinimalDepositAccountInfo
          accountBalance={controller.accountBalance}
          accountNumberLastFour={controller.accountNumberLastFour}
        />
      </ClosedCard>
    );
  }

  return null;
}
