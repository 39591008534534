import { PATH_DEPOSIT_APPLICATION_ENTRY } from '@/constants/routes';
import { JointAccountInvite } from '@/generated/graphql';
import Link from 'next/link';
import React from 'react';

function ItemImage() {
  return (
    <>
      <div className="hidden md:block">
        <svg
          className="min-w-[120px]"
          width="120"
          height="120"
          viewBox="0 0 120 120"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_283_2813)">
            <rect width="120" height="120" rx="8" fill="#0886A5" />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M-26 0H147V18.1918V30.6368V30.638H-26V30.6368V18.1918V0ZM147 32.5057H-26V47.8056H147V32.5057ZM-26 52.2044H147V63.8706H-26V52.2044ZM147 71.9023H-26V79.9348H147V71.9023ZM-26 91.6013H147V96H-26V91.6013Z"
              fill="#006982"
            />
            <path
              d="M20 58.3565C20 56.2078 21.0055 54.1829 22.7174 52.8842L55.3226 28.1493C57.777 26.2874 61.1709 26.2874 63.6254 28.1493L96.2306 52.8842C97.9424 54.1829 98.9479 56.2078 98.9479 58.3565V100.557C98.9479 104.35 95.8727 107.425 92.0792 107.425H26.8688C23.0753 107.425 20 104.35 20 100.557V58.3565Z"
              fill="#39B8D5"
            />
            <rect x="32.2511" y="35.4353" width="54.4469" height="65.7897" rx="6.86879" fill="#F5F6F8" />
            <path
              d="M20.0001 56.3764C20.0001 55.4743 20.4191 54.6234 21.1341 54.0733C22.1987 53.2544 23.686 53.2719 24.731 54.1157L55.1288 78.6604C57.6601 80.7043 61.278 80.692 63.7953 78.631L92.6467 55.009C94.4728 53.5139 97.0922 53.4876 98.948 54.9457V100.557C98.948 104.35 95.8727 107.425 92.0792 107.425H26.8688C23.0753 107.425 20.0001 104.35 20.0001 100.557V56.3764Z"
              fill="#96E6F0"
            />
            <ellipse
              cx="92.3384"
              cy="70.5507"
              rx="18.0748"
              ry="18.0749"
              transform="rotate(-90 92.3384 70.5507)"
              fill="#FDC03F"
            />
            <path
              d="M93.8247 65.3419V68.8555C93.8247 69.2348 94.1323 69.5423 94.5116 69.5423H98.6004C99.1841 69.5423 99.5018 70.2242 99.1263 70.6711L93.2518 77.6621C92.8394 78.1529 92.039 77.8613 92.039 77.2202V73.7067C92.039 73.3273 91.7315 73.0198 91.3521 73.0198H87.2633C86.6797 73.0198 86.362 72.3379 86.7375 71.891L92.612 64.9C93.0244 64.4093 93.8247 64.7009 93.8247 65.3419Z"
              fill="white"
            />
          </g>
          <defs>
            <clipPath id="clip0_283_2813">
              <rect width="120" height="120" rx="8" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </div>
      <div className="block md:hidden">
        <svg
          className="min-w-[72px]"
          width="72"
          height="120"
          viewBox="0 0 72 120"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_283_2867)">
            <rect width="72" height="120" rx="8" fill="#0886A5" />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M-40 -4H133V14.1918V26.6368V26.638H-40V26.6368V14.1918V-4ZM133 28.5057H-40V43.8056H133V28.5057ZM-40 48.2044H133V59.8706H-40V48.2044ZM133 67.9023H-40V75.9348H133V67.9023ZM-40 87.6013H133V92H-40V87.6013Z"
              fill="#006982"
            />
            <path
              d="M-13 58.3565C-13 56.2078 -11.9945 54.1829 -10.2826 52.8842L22.3226 28.1493C24.777 26.2874 28.1709 26.2874 30.6254 28.1493L63.2306 52.8842C64.9424 54.1829 65.9479 56.2078 65.9479 58.3565V100.557C65.9479 104.35 62.8727 107.425 59.0792 107.425H-6.13122C-9.92475 107.425 -13 104.35 -13 100.557V58.3565Z"
              fill="#39B8D5"
            />
            <rect x="-0.748901" y="35.4353" width="54.4469" height="65.7897" rx="6.86879" fill="#F5F6F8" />
            <path
              d="M-12.9999 56.3764C-12.9999 55.4743 -12.5809 54.6234 -11.8659 54.0733C-10.8012 53.2544 -9.31397 53.2719 -8.26891 54.1157L22.1289 78.6604C24.6601 80.7043 28.2781 80.692 30.7954 78.631L59.6467 55.009C61.4728 53.5139 64.0923 53.4876 65.9481 54.9457V100.557C65.9481 104.35 62.8728 107.425 59.0793 107.425H-6.1311C-9.92462 107.425 -12.9999 104.35 -12.9999 100.557V56.3764Z"
              fill="#96E6F0"
            />
            <ellipse
              cx="59.3384"
              cy="70.5507"
              rx="18.0748"
              ry="18.0749"
              transform="rotate(-90 59.3384 70.5507)"
              fill="#FDC03F"
            />
            <path
              d="M60.8247 65.3419V68.8555C60.8247 69.2348 61.1322 69.5423 61.5116 69.5423H65.6004C66.1841 69.5423 66.5018 70.2242 66.1263 70.6711L60.2517 77.6621C59.8393 78.1529 59.039 77.8613 59.039 77.2202V73.7067C59.039 73.3273 58.7315 73.0198 58.3521 73.0198H54.2633C53.6796 73.0198 53.3619 72.3379 53.7374 71.891L59.612 64.9C60.0244 64.4093 60.8247 64.7009 60.8247 65.3419Z"
              fill="white"
            />
          </g>
          <defs>
            <clipPath id="clip0_283_2867">
              <rect width="72" height="120" rx="8" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </div>
    </>
  );
}

export function JointInvitedItem(props: { activeInvite: JointAccountInvite }) {
  return (
    <div className="rounded-lg bg-white px-4 md:px-6 py-5 flex items-center justify-between h-full">
      <div className="flex items-center w-full">
        <ItemImage />
        <div className="flex-1 ml-4 md:ml-6">
          <div className="flex items-center justify-between">
            <p className="text-md text-gray-950 leading-6 md:mb-0 mb-0.5">Accept Joint Account invitation</p>
          </div>
          <p className="text-base text-gray-950 font-normal mb-2.5">
            {props.activeInvite.inviterFirstName} {props.activeInvite.inviterLastName} has invited you to be a joint
            accountholder. Use Routines with your joint account to automate your shared finances.
          </p>
          <Link href={PATH_DEPOSIT_APPLICATION_ENTRY}>
            <div className="rounded-md bg-blue text-white text-base py-2.5 px-6 w-fit">Accept invitation now</div>
          </Link>
        </div>
      </div>
    </div>
  );
}
