import { CurrencyFormatter } from '@/i18n/numbers';
import cx from 'clsx';

type BalanceProps = {
  className?: string;
  testId?: string;
  amount: string | number;
  // NOTE: amount to scale down (percent) with each added figure (0–1)
  // A good default would be: { autoScale: 0.1 }
  autoScale?: number;
  hasCents?: boolean;
};

export function Balance(props: BalanceProps) {
  const { autoScale = 0, hasCents = true } = props;
  // NOTE: this automatically scales the amount based on length
  // excluding cents assuming largest number is 6 figures
  const { length } = parseInt(props.amount.toString(), 10).toString();
  const scale = Math.max(1 - (length - 1) * autoScale, 0.5);
  const autoSize = `${scale}em`;

  const [numberWithCurrency, decimalNumber] = CurrencyFormatter.format(
    typeof props.amount === 'number' ? props.amount : parseFloat(props.amount),
  ).split('.');
  const currencySymbol = numberWithCurrency.slice(0, 1);
  const integer = numberWithCurrency.slice(1);

  return (
    <span className={cx(props.className, 'font-serif')} data-testid={props.testId}>
      <span className="balance-auto-size" style={{ '--autoSize': autoSize } as React.CSSProperties}>
        <span className="inline-block h-fit mt-xs mr-0.5 text-[0.6em] self-start leading-none">{currencySymbol}</span>
        <span className="inline-block h-fit leading-none">{integer}</span>
        {hasCents && (
          <span className="inline-block h-fit mt-xs ml-0.5 text-[0.6em] leading-none">.{decimalNumber}</span>
        )}
      </span>
    </span>
  );
}
