import { ExclamationCircleIcon } from '@heroicons/react/24/outline';

import { CashflowSummaryHeader } from '@/routes/account/routes/home/components/cashflow-summary/components/cashflow-summary-header';

export function CashflowSummaryError() {
  return (
    <div className="mb-6">
      <CashflowSummaryHeader text="Unable to load the data" />
      <div className="flex p-2 items-center border-2 border-red-light rounded-lg mt-1.5">
        <ExclamationCircleIcon className="w-6 h-6 text-red" />
        <p className="ml-2 text-red text-sm font-normal">We’re having trouble loading your account right now</p>
      </div>
    </div>
  );
}
