import { ErrorIcon } from '@/routes/account/routes/accounts-overview/components/images';

export function CardAccountError() {
  return (
    <div className="flex-1 rounded-lg bg-white py-6 px-7 flex flex-row items-center">
      <ErrorIcon />
      <p className="text-base text-red-900 font-normal ml-4">We’re having trouble loading your account right now</p>
    </div>
  );
}
