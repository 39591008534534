import { useBalanceView } from './hooks/use-balance-view';

type BalanceProps = {
  className?: string;
  amount: string | number;
  hasCents?: boolean;
};

export function BalanceCardView(props: BalanceProps) {
  const { hasCents = true } = props;
  const controller = useBalanceView(props);
  return (
    <span className={props.className}>
      <span className="balance-auto-size">
        <span className="inline-block h-fit mt-xs mr-0.5 self-start leading-none">{controller.currencySymbol}</span>
        <span className="inline-block h-fit leading-none">{controller.integer}</span>
        {hasCents && <span className="inline-block h-fit mt-xs ml-0.5 leading-none">.{controller.decimalNumber}</span>}
      </span>
    </span>
  );
}
