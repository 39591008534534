import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import React from 'react';
import 'swiper/css';
import 'swiper/css/pagination';
import { useSwiper } from 'swiper/react';

export function Navigation() {
  const swiper = useSwiper();

  if (swiper.slides.length <= 0) {
    return null;
  }

  return (
    <div className="w-full flex justify-center mt-2">
      <ChevronLeftIcon className="w-5 h-5 cursor-pointer" onClick={() => swiper.slidePrev()} />
      <p className="text-base text-gray-950 font-normal mx-4">
        {swiper.activeIndex + 1} of {swiper.slides.length}
      </p>
      <ChevronRightIcon className="w-5 h-5 cursor-pointer" onClick={() => swiper.slideNext()} />
    </div>
  );
}
