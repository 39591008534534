import { PATH_ROUTINES_LIST } from '@/constants/routes';
import { XMarkIcon } from '@heroicons/react/24/outline';
import Link from 'next/link';
import React from 'react';
import { useSwiper } from 'swiper/react';

function ItemImage() {
  return (
    <>
      <div className="hidden md:block">
        <svg
          className="min-w-[120px]"
          width="120"
          height="120"
          viewBox="0 0 120 120"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_69_1279)">
            <rect width="120" height="120" rx="8" fill="#D3F5F8" />
            <path
              d="M68.2002 31.6C68.2002 53.4705 50.4706 71.2 28.6002 71.2C6.72969 71.2 -10.9998 53.4705 -10.9998 31.6C-10.9998 9.72952 6.72969 -8 28.6002 -8C50.4706 -8 68.2002 9.72952 68.2002 31.6Z"
              fill="#7561E2"
            />
            <path
              d="M56.6014 3.59857C49.175 -3.82787 39.1026 -8 28.6 -8C18.0974 -8 8.02501 -3.82787 0.598574 3.59857C-6.82787 11.025 -11 21.0974 -11 31.6C-11 42.1026 -6.82787 52.175 0.598568 59.6014L56.6014 3.59857Z"
              fill="#5A46D7"
            />
            <path
              d="M54.5877 31.6C54.5877 45.9525 42.9527 57.5875 28.6002 57.5875C14.2477 57.5875 2.61267 45.9525 2.61267 31.6C2.61267 17.2475 14.2477 5.61255 28.6002 5.61255C42.9527 5.61255 54.5877 17.2475 54.5877 31.6Z"
              fill="#927EEE"
            />
            <path
              d="M46.9761 13.2236C44.5629 10.8105 41.6981 8.89623 38.5451 7.59024C35.3922 6.28425 32.0129 5.61206 28.6001 5.61206C25.1874 5.61206 21.8081 6.28425 18.6552 7.59024C15.5022 8.89623 12.6374 10.8105 10.2242 13.2236C7.81104 15.6368 5.89681 18.5016 4.59082 21.6546C3.28483 24.8075 2.61264 28.1868 2.61264 31.5996C2.61264 35.0123 3.28483 38.3916 4.59082 41.5445C5.89682 44.6975 7.81104 47.5623 10.2242 49.9755L46.9761 13.2236Z"
              fill="#7561E2"
            />
            <path
              d="M40.9756 31.6003C40.9756 38.4349 35.4351 43.9753 28.6006 43.9753C21.7661 43.9753 16.2256 38.4349 16.2256 31.6003C16.2256 24.7658 21.7661 19.2253 28.6006 19.2253C35.4351 19.2253 40.9756 24.7658 40.9756 31.6003Z"
              fill="#E0D7FF"
            />
            <path d="M44.3972 85.4972L87.5974 128.697V42.2969L44.3972 85.4972Z" fill="#39B8D5" />
            <path d="M130.797 85.4965L87.5973 42.2969V128.697L130.797 85.4965Z" fill="#39B8D5" />
            <path d="M113.771 85.497L87.5972 59.3228L87.5972 85.497L113.771 85.497Z" fill="#56CBE3" />
            <path d="M87.5972 111.671L113.771 85.497L87.5972 85.497L87.5972 111.671Z" fill="#56CBE3" />
            <path d="M87.5967 59.3228L61.4224 85.497L87.5967 85.497L87.5967 59.3228Z" fill="#76D9EC" />
            <path d="M61.4224 85.497L87.5967 111.671L87.5967 85.497L61.4224 85.497Z" fill="#76D9EC" />
            <path d="M99.7107 85.5012L87.5977 73.3882V85.5012H99.7107Z" fill="#B5EEF4" />
            <path d="M87.5977 97.6143L99.7107 85.5012H87.5977V97.6143Z" fill="#B5EEF4" />
            <path d="M87.5975 73.3882L75.4844 85.5012L87.5975 85.5012L87.5975 73.3882Z" fill="#B5EEF4" />
            <path d="M75.4844 85.5012L87.5975 97.6143L87.5975 85.5012L75.4844 85.5012Z" fill="#B5EEF4" />
            <path
              d="M29 32H48.7926C54.7573 32 59.5926 36.8353 59.5926 42.8V74.2C59.5926 80.1647 64.4279 85 70.3926 85H88"
              stroke="#F5F6F8"
              strokeWidth="2.7"
              strokeLinejoin="round"
              strokeDasharray="0.01 5.4"
            />
            <ellipse cx="87.8" cy="84.8" rx="1.8" ry="1.8" fill="white" />
            <ellipse cx="28.8" cy="31.8" rx="1.8" ry="1.8" fill="white" />
          </g>
          <defs>
            <clipPath id="clip0_69_1279">
              <rect width="120" height="120" rx="8" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </div>
      <div className="block md:hidden">
        <svg width="72" height="119" viewBox="0 0 72 119" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_69_1710)">
            <rect width="72" height="119" rx="8" fill="#D3F5F8" />
            <path
              d="M44.2001 31.6C44.2001 53.4705 26.4706 71.2 4.60012 71.2C-17.2704 71.2 -34.9999 53.4705 -34.9999 31.6C-34.9999 9.72952 -17.2704 -8 4.60012 -8C26.4706 -8 44.2001 9.72952 44.2001 31.6Z"
              fill="#7561E2"
            />
            <path
              d="M32.6014 3.59857C25.175 -3.82787 15.1026 -8 4.6 -8C-5.90257 -8 -15.975 -3.82787 -23.4014 3.59857C-30.8279 11.025 -35 21.0974 -35 31.6C-35 42.1026 -30.8279 52.175 -23.4014 59.6014L32.6014 3.59857Z"
              fill="#5A46D7"
            />
            <path
              d="M30.5877 31.6C30.5877 45.9525 18.9527 57.5875 4.60017 57.5875C-9.75233 57.5875 -21.3873 45.9525 -21.3873 31.6C-21.3873 17.2475 -9.75233 5.61255 4.60017 5.61255C18.9527 5.61255 30.5877 17.2475 30.5877 31.6Z"
              fill="#927EEE"
            />
            <path
              d="M22.9761 13.2236C20.5629 10.8105 17.6981 8.89623 14.5452 7.59024C11.3922 6.28425 8.0129 5.61206 4.60017 5.61206C1.18744 5.61206 -2.19187 6.28425 -5.34482 7.59024C-8.49777 8.89623 -11.3626 10.8105 -13.7758 13.2236C-16.1889 15.6368 -18.1032 18.5016 -19.4091 21.6546C-20.7151 24.8075 -21.3873 28.1868 -21.3873 31.5996C-21.3873 35.0123 -20.7151 38.3916 -19.4091 41.5445C-18.1032 44.6975 -16.1889 47.5623 -13.7758 49.9755L22.9761 13.2236Z"
              fill="#7561E2"
            />
            <path
              d="M16.9756 31.6003C16.9756 38.4349 11.4351 43.9753 4.60058 43.9753C-2.23394 43.9753 -7.77441 38.4349 -7.77441 31.6003C-7.77441 24.7658 -2.23394 19.2253 4.60058 19.2253C11.4351 19.2253 16.9756 24.7658 16.9756 31.6003Z"
              fill="#E0D7FF"
            />
            <path d="M20.3972 85.4972L63.5975 128.697V42.2969L20.3972 85.4972Z" fill="#39B8D5" />
            <path d="M106.797 85.4965L63.5974 42.2969V128.697L106.797 85.4965Z" fill="#39B8D5" />
            <path d="M89.7715 85.497L63.5972 59.3228L63.5972 85.497L89.7715 85.497Z" fill="#56CBE3" />
            <path d="M63.5972 111.671L89.7715 85.497L63.5972 85.497L63.5972 111.671Z" fill="#56CBE3" />
            <path d="M63.5968 59.3228L37.4225 85.497L63.5968 85.497L63.5968 59.3228Z" fill="#76D9EC" />
            <path d="M37.4225 85.497L63.5968 111.671L63.5968 85.497L37.4225 85.497Z" fill="#76D9EC" />
            <path d="M75.7108 85.5012L63.5978 73.3882V85.5012H75.7108Z" fill="#B5EEF4" />
            <path d="M63.5978 97.6143L75.7108 85.5012H63.5978V97.6143Z" fill="#B5EEF4" />
            <path d="M63.5975 73.3882L51.4845 85.5012L63.5975 85.5012L63.5975 73.3882Z" fill="#B5EEF4" />
            <path d="M51.4845 85.5012L63.5975 97.6143L63.5975 85.5012L51.4845 85.5012Z" fill="#B5EEF4" />
            <path
              d="M5 32H24.7926C30.7573 32 35.5926 36.8353 35.5926 42.8V74.2C35.5926 80.1647 40.4279 85 46.3926 85H64"
              stroke="#F5F6F8"
              strokeWidth="2.7"
              strokeLinejoin="round"
              strokeDasharray="0.01 5.4"
            />
            <ellipse cx="63.8" cy="84.8" rx="1.8" ry="1.8" fill="white" />
            <ellipse cx="4.8" cy="31.8" rx="1.8" ry="1.8" fill="white" />
          </g>
          <defs>
            <clipPath id="clip0_69_1710">
              <rect width="72" height="119" rx="8" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </div>
    </>
  );
}

export function RoutinesItem(props: { onClose: () => void }) {
  const swiper = useSwiper();

  function onClose() {
    swiper.removeSlide(swiper.activeIndex);
    props.onClose();
  }

  return (
    <div className="rounded-lg bg-white px-4 md:px-6 py-5 flex items-center justify-between h-full">
      <div className="flex items-center w-full">
        <ItemImage />
        <div className="flex-1 ml-4 md:ml-6">
          <div className="flex items-center justify-between">
            <p className="text-md text-gray-950 leading-6 md:mb-0 mb-0.5">Finish setting up your Routine</p>
            <div
              role="button"
              onClick={onClose}
              className="cursor-pointer rounded-full p-2 -mr-1 -mt-1 flex items-center justify-center text-gray-950 hover:bg-gray-50 duration-200"
            >
              <XMarkIcon width={20} height={20} />
            </div>
          </div>
          <p className="text-base text-gray-950 font-normal mb-3">
            Your drafted Routine is waiting for you! Unlock the power of financial automation by adding the finishing
            touches today.
          </p>
          <Link href={PATH_ROUTINES_LIST}>
            <div className="rounded-lg text-gray-950 text-base py-2 px-6 w-fit border-1 border-gray-200">
              Go to Routines
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}
