import { CashflowSummaryHeader } from '@/routes/account/routes/home/components/cashflow-summary/components/cashflow-summary-header';

export function CashflowSummarySkeleton() {
  return (
    <div className="h-72 w-full flex flex-col mb-6">
      <CashflowSummaryHeader text="Loading..." />
      <div className="bg-white-background rounded-lg flex-1 animate-pulse mt-2" />
    </div>
  );
}
