import { Transaction, TransactionStatus } from '@/generated/graphql';
import { TransferItem } from '@/types/types';

export function sortDate(a: string, b: string) {
  if (!a || !b) return 0;

  const aDate = new Date(a).valueOf();
  const bDate = new Date(b).valueOf();

  if (aDate > bDate) {
    return 1;
  }
  if (aDate === bDate) {
    return 0;
  }
  return -1;
}

export const sortByDateKeyDesc = (key: string) => (a: TransferItem, b: TransferItem) => sortDate(b[key], a[key]);

export const sortByDateKeyAsc = (key: string) => (a: TransferItem, b: TransferItem) => sortDate(a[key], b[key]);

export function byNotCanceledTransactions(t: Transaction) {
  return t.status !== TransactionStatus.Cancelled;
}

export function byTransactionDate(a: Transaction, b: Transaction) {
  if (!a.postedAt && !b.postedAt) {
    return sortDate(b.initiatedAt, a.initiatedAt);
  }
  if (!a.postedAt) {
    return sortDate(b.postedAt, a.initiatedAt);
  }
  if (!b.postedAt) {
    return sortDate(b.initiatedAt, a.postedAt);
  }
  return 0;
}
