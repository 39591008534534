import { useFeatureWithUserStrategies } from '@/components/feature-flag/hooks/use-feature-with-user-strategies';
import { FEATURE_FLAG_JOINT_ACCOUNTS } from '@/constants/features';
import { PATH_DEPOSIT_APPLICATION_INDIVIDUAL } from '@/constants/routes';
import Link from 'next/link';
import React from 'react';

function InvitedBody() {
  return (
    <div className="flex flex-col gap-1">
      <p className="text-sm font-normal text-grey-lighter">You’re invited!</p>
      <div className="flex justify-between">
        <h3 className="font-medium text-lg leading-none">Individual Deposit Account</h3>
        <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M5 9V5C5 2.79086 6.79086 1 9 1C11.2091 1 13 2.79086 13 5M9 13V15M3 19H15C16.1046 19 17 18.1046 17 17V11C17 9.89543 16.1046 9 15 9H3C1.89543 9 1 9.89543 1 11V17C1 18.1046 1.89543 19 3 19Z"
            stroke="#0B1012"
            strokeWidth="2"
            strokeLinecap="round"
          />
        </svg>
      </div>
    </div>
  );
}

function InvitedImg() {
  const clipPath0 = React.useId();

  return (
    <svg
      className="rounded-t-lg"
      width="100%"
      height="100%"
      viewBox="0 0 314 150"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath={`url(#${clipPath0})`}>
        <rect width="314" height="150" fill="#56CBE3" />
        <circle cx="89" cy="50" r="50" fill="#76D9EC" />
        <path d="M89 50H91C117.51 50 139 71.4903 139 98V100H89V50Z" fill="#B5EEF4" />
        <path d="M64 75V75C77.8071 75 89 86.1929 89 100V100H64V75Z" fill="#6EF499" />
        <path
          d="M0 125C0 111.193 11.1929 100 25 100H214V100C214 127.614 191.614 150 164 150H25C11.1929 150 0 138.807 0 125V125Z"
          fill="#0886A5"
        />
        <rect x="189" y="75" width="25" height="25" fill="#56CBE3" />
        <path d="M139 75C139 61.1929 150.193 50 164 50V50C177.807 50 189 61.1929 189 75V100H139V75Z" fill="#0886A5" />
        <path d="M189 50L164 75H189V50Z" fill="#B4FDCB" />
        <path d="M214 50L189 75H214V50Z" fill="#D1FFDF" />
        <path d="M189 75L164 100H189V75Z" fill="#D1FFDF" />
        <path d="M164 75L139 100H164V75Z" fill="#B4FDCB" />
        <path d="M189 25L164 50H189V25Z" fill="#6EF499" />
        <path d="M214 0L189 25H214V0Z" fill="#43EA79" />
        <path d="M214 25L189 50H214V25Z" fill="#96FAB5" />
        <path d="M139 75L114 100H139V75Z" fill="#B4FDCB" />
        <path d="M164 50L139 75H164V50Z" fill="#96FAB5" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M214 0H314V28.4229V47.87V47.8701H214V47.87V28.4229V0ZM314 50.7894H214V74.6954H314V50.7894ZM214 81.5684H314V99.7968H214V81.5684ZM314 112.348H214V124.899H314V112.348ZM214 143.127H314V150H214V143.127Z"
          fill="#0886A5"
        />
      </g>
      <defs>
        <clipPath id={clipPath0}>
          <rect width="314" height="150" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

function Invited() {
  return (
    <div className="flex justify-between flex-col bg-white rounded-t-xl col-span-12 lg:col-span-4">
      <InvitedImg />
      <div className="flex-1 flex flex-col gap-4 p-6 rounded-b-lg">
        <div className="flex flex-col gap-4">
          <InvitedBody />
          <p className="font-normal">
            Open your HMBradley Deposit Account today and start earning up to 4.70% APY<sup>1</sup> on your balance.
          </p>
        </div>
        <Link
          href={{
            pathname: PATH_DEPOSIT_APPLICATION_INDIVIDUAL,
          }}
          passHref
          className="w-full text-center text-white rounded-lg py-2.5 text-base bg-purple-400 hover:opacity-90"
        >
          Open Now
        </Link>
      </div>
    </div>
  );
}

function InvitedNewImage() {
  return (
    <svg viewBox="0 0 344 120" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_5451_58192)">
        <rect width="344" height="120" fill="#39B8D5" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M138 0H344V21.0332V35.4239V35.4241H138V35.4239V21.0332V0ZM344 37.585H138V55.2755H344V37.585ZM138 60.3613H344V73.8504H138V60.3613ZM344 83.1377H138V92.4252H344V83.1377ZM138 105.914H344V111H138V105.914Z"
          fill="#1EA1C1"
        />
        <circle cx="131.5" cy="45.5" r="54.5" fill="#56CBE3" />
        <path
          d="M203 61.5C203 46.8645 214.864 35 229.5 35C244.136 35 256 46.8645 256 61.5V92H203V61.5Z"
          fill="#6EF499"
        />
        <path d="M155 29H161C187.51 29 209 50.4903 209 77V92H155V29Z" fill="#D3F5F8" />
        <path d="M132 64H133C148.464 64 161 76.536 161 92H132V64Z" fill="#43EA79" />
        <path
          d="M80 110.5C80 100.283 88.2827 92 98.5 92H294C294 112.435 277.435 129 257 129H98.5C88.2827 129 80 120.717 80 110.5Z"
          fill="#0886A5"
        />
      </g>
      <defs>
        <clipPath id="clip0_5451_58192">
          <rect width="344" height="120" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

function InvitedNew() {
  return (
    <div className="bg-white overflow-hidden flex flex-col rounded-lg col-span-12 lg:col-span-4">
      <InvitedNewImage />
      <div className="p-6 flex-1 flex flex-col justify-between">
        <div>
          <p className="text-sm font-normal text-gray-500">You’re invited!</p>
          <h3 className="font-medium text-lg text-gray-950 mb-2 leading-6">Individual Deposit Account</h3>
          <p className="font-normal text-base text-gray-900 mb-4">
            Open your HMBradley Deposit Account today and start earning up to 4.70% APY<sup>1</sup> on your balance.
          </p>
        </div>
        <Link
          href={{
            pathname: PATH_DEPOSIT_APPLICATION_INDIVIDUAL,
          }}
          className="block w-full text-center text-white rounded-lg py-2.5 text-base bg-blue-800 hover:opacity-90"
        >
          Apply Now
        </Link>
      </div>
    </div>
  );
}

export function OpenDepositInvitation() {
  const jointAccountsFeatureFlag = useFeatureWithUserStrategies(FEATURE_FLAG_JOINT_ACCOUNTS);

  return jointAccountsFeatureFlag.isEnabled ? <InvitedNew /> : <Invited />;
}
