import { PATH_DEPOSIT_APPLICATION_ENTRY } from '@/constants/routes';
import { XMarkIcon } from '@heroicons/react/24/outline';
import Link from 'next/link';
import React from 'react';
import { useSwiper } from 'swiper/react';

function ItemImage() {
  return (
    <>
      <div className="hidden md:block">
        <svg
          className="min-w-[120px]"
          width="120"
          height="120"
          viewBox="0 0 120 120"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_47_1219)">
            <rect width="120" height="120" rx="8" fill="#0886A5" />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M-26 0H147V18.1918V30.6368V30.638H-26V30.6368V18.1918V0ZM147 32.5057H-26V47.8056H147V32.5057ZM-26 52.2044H147V63.8706H-26V52.2044ZM147 71.9023H-26V79.9348H147V71.9023ZM-26 91.6013H147V96H-26V91.6013Z"
              fill="#006982"
            />
            <circle cx="66.5" cy="56.5" r="44.5" fill="#0886A5" />
            <circle cx="18.5" cy="56.5" r="44.5" fill="#1EA1C1" />
            <path
              d="M105.258 76.4518C105.258 67.0095 112.912 59.355 122.355 59.355C131.797 59.355 139.451 67.0095 139.451 76.4518V96.1292H105.258V76.4518Z"
              fill="#2ADA62"
            />
            <path
              d="M109.129 55.4839H98.2903C85.0355 55.4839 74.2903 66.2291 74.2903 79.4839V96.129H109.129V55.4839Z"
              fill="#6EF499"
            />
            <path
              d="M49.129 55.4839H59.9677C73.2226 55.4839 83.9677 66.2291 83.9677 79.4839V96.129H49.129V55.4839Z"
              fill="#B4FDCB"
            />
            <path d="M34.2903 78.0645H34.9355C44.9122 78.0645 53 86.1522 53 96.129H34.2903V78.0645Z" fill="#43EA79" />
            <path d="M26 96H147C147 109.255 136.255 120 123 120H50C36.7452 120 26 109.255 26 96Z" fill="#39B8D5" />
          </g>
          <defs>
            <clipPath id="clip0_47_1219">
              <rect width="120" height="120" rx="8" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </div>
      <div className="block md:hidden">
        <svg width="72" height="137" viewBox="0 0 72 137" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_49_4400)">
            <rect width="72" height="137" rx="8" fill="#0886A5" />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M-50 0H123V18.1918V30.6368V30.638H-50V30.6368V18.1918V0ZM123 32.5057H-50V47.8056H123V32.5057ZM-50 52.2044H123V63.8706H-50V52.2044ZM123 71.9023H-50V79.9348H123V71.9023ZM-50 91.6013H123V96H-50V91.6013Z"
              fill="#006982"
            />
            <circle cx="42.5" cy="73.5" r="44.5" fill="#0886A5" />
            <circle cx="-5.5" cy="73.5" r="44.5" fill="#1EA1C1" />
            <path
              d="M85.129 72.4839H74.2903C61.0355 72.4839 50.2903 83.2291 50.2903 96.4839V113.129H85.129V72.4839Z"
              fill="#6EF499"
            />
            <path
              d="M25.129 72.4839H35.9677C49.2226 72.4839 59.9677 83.2291 59.9677 96.4839V113.129H25.129V72.4839Z"
              fill="#B4FDCB"
            />
            <path d="M10.2903 95.0645H10.9354C20.9122 95.0645 29 103.152 29 113.129H10.2903V95.0645Z" fill="#43EA79" />
            <path d="M2 113H123C123 126.255 112.255 137 99 137H26C12.7452 137 2 126.255 2 113Z" fill="#39B8D5" />
          </g>
          <defs>
            <clipPath id="clip0_49_4400">
              <rect width="72" height="137" rx="8" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </div>
    </>
  );
}

export function JointInvitationItem(props: { onClose: () => void }) {
  const swiper = useSwiper();

  function onClose() {
    swiper.removeSlide(swiper.activeIndex);
    props.onClose();
  }

  return (
    <div className="rounded-lg bg-white px-4 md:px-6 py-5 flex items-center justify-between h-full">
      <div className="flex items-center w-full">
        <ItemImage />
        <div className="flex-1 ml-4 md:ml-6">
          <div className="flex items-center justify-between">
            <p className="text-md text-gray-950 leading-6 md:mb-0 mb-0.5">Open a high-yield joint deposit account</p>
            <div
              role="button"
              onClick={onClose}
              className="cursor-pointer rounded-full p-2 -mr-1 -mt-1 flex items-center justify-center text-gray-950 hover:bg-gray-50 duration-200"
            >
              <XMarkIcon width={20} height={20} />
            </div>
          </div>
          <p className="text-base text-gray-950 font-normal mb-2.5">
            Have a roommate? A spouse? Open a joint account to begin automating your shared household expenses.
          </p>
          <Link href={PATH_DEPOSIT_APPLICATION_ENTRY}>
            <div className="rounded-md bg-blue text-white text-base py-2.5 px-6 w-fit">Apply now</div>
          </Link>
        </div>
      </div>
    </div>
  );
}
