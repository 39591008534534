import { CompliancePolicy } from '@/types/mainframe';
import { OfferPromo, OfferPromoInterval } from '@/types/offer';
import { PolicyNames } from './types';

const findHardPullPolicy = (p: CompliancePolicy) => p.policyName === PolicyNames.HardPull;
const findCardAgreementPolicy = (p: CompliancePolicy) => p.policyName === PolicyNames.CardAgreement;
const byPrivacyNotice = (p: CompliancePolicy) => p.policyName === PolicyNames.PrivacyNotice;
const byPatriotAct = (p: CompliancePolicy) => p.policyName === PolicyNames.PatriotAct;
const byMrvPii = (p: CompliancePolicy) => p.policyName === PolicyNames.MrvPii;

export function cardAgreementPolicy(policies: CompliancePolicy[]): CompliancePolicy {
  return policies.find(findCardAgreementPolicy);
}

export function getPrivacyNotice(policies: CompliancePolicy[]): CompliancePolicy {
  return policies.find(byPrivacyNotice);
}

export function getPatriotAct(policies: CompliancePolicy[]): CompliancePolicy {
  return policies.find(byPatriotAct);
}

export function getMrvPii(policies: CompliancePolicy[]): CompliancePolicy {
  return policies.find(byMrvPii);
}

export function getHardPullPolicy(policies: CompliancePolicy[]) {
  const hardPull = policies.find(findHardPullPolicy);
  if (hardPull) {
    return {
      policyName: PolicyNames.HardPull,
      policyID: hardPull.id,
      policyVersionID: hardPull.policyVersionID,
      consentType: 'Click',
      consentedAt: new Date().toISOString(),
    };
  } else return null;
}

export function formatPromoInterval(promo: OfferPromo) {
  switch (promo.interval) {
    case OfferPromoInterval.Monthly:
      return `${promo.value} months`;
    default:
      return '';
  }
}
