import { HOW_DO_I_OPEN_A_CREDIT_CARD_ACCOUNT_FAQ } from '@/constants/app';
import { PATH_DEPOSIT_APPLICATION_ENTRY } from '@/constants/routes';
import { XMarkIcon } from '@heroicons/react/24/outline';
import Link from 'next/link';
import React from 'react';
import { useSwiper } from 'swiper/react';

function ItemImage() {
  return (
    <>
      <div className="hidden md:block">
        <svg
          className="min-w-[120px]"
          width="120"
          height="120"
          viewBox="0 0 120 120"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_47_1113)">
            <rect width="120" height="120" rx="8" fill="#39B8D5" />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M-18 0H126.738V18.1918V30.6368V30.638H-18V30.6368V18.1918V0ZM126.738 32.5057H-18V47.8056H126.738V32.5057ZM-18 52.2044H126.738V63.8706H-18V52.2044ZM126.738 71.9026H-18V79.935H126.738V71.9026ZM-18 91.6013H126.738V96H-18V91.6013Z"
              fill="#1EA1C1"
            />
            <circle cx="28.5" cy="54.5" r="42.5" fill="#56CBE3" />
            <path
              d="M83 69.5C83 59.2827 91.2827 51 101.5 51C111.717 51 120 59.2827 120 69.5V97H83V69.5Z"
              fill="#6EF499"
            />
            <path d="M49 48H50.6C71.8077 48 89 65.1923 89 86.4V97H49V48Z" fill="#D1FFDF" />
            <path d="M34 76H36C47.598 76 57 85.402 57 97H34V76Z" fill="#43EA79" />
            <path
              d="M134 108C134 101.373 128.627 96 122 96H10C10 109.255 20.7452 120 34 120H122C128.627 120 134 114.627 134 108Z"
              fill="#0886A5"
            />
          </g>
          <defs>
            <clipPath id="clip0_47_1113">
              <rect width="120" height="120" rx="8" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </div>
      <div className="block md:hidden">
        <svg width="72" height="151" viewBox="0 0 72 151" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_49_4369)">
            <rect width="72" height="151" rx="8" fill="#39B8D5" />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M-66 0H78.7382V18.1918V30.6368V30.638H-66V30.6368V18.1918V0ZM78.7382 32.5057H-66V47.8056H78.7382V32.5057ZM-66 52.2044H78.7382V63.8706H-66V52.2044ZM78.7382 71.9026H-66V79.935H78.7382V71.9026ZM-66 91.6013H78.7382V96H-66V91.6013Z"
              fill="#1EA1C1"
            />
            <circle cx="4.5" cy="85.5" r="42.5" fill="#56CBE3" />
            <path
              d="M59 100.5C59 90.2827 67.2827 82 77.5 82C87.7173 82 96 90.2827 96 100.5V128H59V100.5Z"
              fill="#6EF499"
            />
            <path d="M25 79H26.6C47.8077 79 65 96.1923 65 117.4V128H25V79Z" fill="#D1FFDF" />
            <path d="M10 107H12C23.598 107 33 116.402 33 128H10V107Z" fill="#43EA79" />
            <path
              d="M110 139C110 132.373 104.627 127 98 127H-14C-14 140.255 -3.25484 151 10 151H98C104.627 151 110 145.627 110 139Z"
              fill="#0886A5"
            />
          </g>
          <defs>
            <clipPath id="clip0_49_4369">
              <rect width="72" height="151" rx="8" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </div>
    </>
  );
}

export function DepositInvitationItem(props: { onClose: () => void }) {
  const swiper = useSwiper();

  function onClose() {
    swiper.removeSlide(swiper.activeIndex);
    props.onClose();
  }

  return (
    <div className="rounded-lg bg-white px-4 md:px-6 py-5 flex items-center justify-between h-full">
      <div className="flex items-center w-full">
        <ItemImage />
        <div className="flex-1 ml-4 md:ml-6">
          <div className="flex items-center justify-between">
            <p className="text-md text-gray-950 leading-6 md:mb-0 mb-0.5">Open your high-yield deposit account</p>
            <div
              role="button"
              onClick={onClose}
              className="cursor-pointer rounded-full p-2 -mr-1 -mt-1 flex items-center justify-center text-gray-950 hover:bg-gray-50 duration-200"
            >
              <XMarkIcon width={20} height={20} />
            </div>
          </div>
          <p className="text-base text-gray-950 font-normal mb-2.5 pr-6">
            Start building strong financial habits by automating money movement with Routines. Unlock Routines and more
            with an HMBradley Deposit account.
          </p>
          <Link href={PATH_DEPOSIT_APPLICATION_ENTRY}>
            <div className="rounded-md bg-blue text-white text-base py-2.5 px-6 w-fit">Apply now</div>
          </Link>
          <div className="flex gap-1 pt-2">
            <p className="text-blue-800 text-tiny pb-3">*</p>
            <a
              className="text-blue-800 underline text-tiny"
              target="_blank"
              rel="noreferrer"
              href={HOW_DO_I_OPEN_A_CREDIT_CARD_ACCOUNT_FAQ}
            >
              Terms apply
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
