import { Balance } from '@/components/balance-view';
import { Button } from '@/components/buttons-main/button';
import { useFeatureWithUserStrategies } from '@/components/feature-flag/hooks/use-feature-with-user-strategies';
import { FEATURE_FLAG_ENABLED_MRV_CARD } from '@/constants/features';
import { ACCOUNT_ONE_CLICK_CREDIT_OFFERS_OFFER_ID_PATH } from '@/constants/routes';
import { routeFor } from '@/helpers/router';
import { formatPercent } from '@/helpers/to-percent';
import { UTCDateLongFormatter } from '@/i18n/datetime';
import { formatPromoInterval } from '@/routes/account/routes/one-click-credit/helpers';
import { httpClient } from '@/transports/http';
import { UseGetDeciderV1OffersResult } from '@/types/mainframe';
import { Offer, OfferPromo } from '@/types/offer';
import { useGetDeciderV1Offers } from '@core/mainframe-react-query';
import clsx from 'clsx';

function useCreditOffer() {
  const offersQuery = useGetDeciderV1Offers<UseGetDeciderV1OffersResult>(httpClient, {});

  return {
    isLoading: offersQuery.isLoading,
    isError: offersQuery.isError,
    creditCardOffers: offersQuery.data?.creditCardOffers,
  };
}

function FixedIntroRate(props: { promo: OfferPromo }) {
  return (
    <div className="flex flex-col gap-1 w-1/2">
      <p className="text-sm uppercase">Fixed intro rate</p>
      <p className="text-2xl font-medium font-serif leading-none">
        {formatPercent(parseFloat(props.promo.interestRate))} APR*
        {props.promo && (
          <span className="text-sm font-sans font-normal"> your first {formatPromoInterval(props.promo)}</span>
        )}
      </p>
    </div>
  );
}

function Rate(props: { offer: Offer }) {
  return (
    <div className="flex flex-col gap-1 w-1/2">
      <p className="text-sm uppercase">{props.offer.promo ? 'Fixed rate after intro period' : 'Your rate'}</p>
      <p className="text-2xl font-medium font-serif leading-none">
        {formatPercent(parseFloat(props.offer.interestRate))} APR*
      </p>
    </div>
  );
}

function CreditLimit(props: { offer: Offer }) {
  return (
    <div className="flex flex-col gap-1 w-1/2">
      <p className="text-sm uppercase">your credit limit</p>
      <p className="text-2xl font-medium font-serif leading-none">
        <Balance hasCents={true} amount={props.offer.creditLine} />
      </p>
    </div>
  );
}

function OfferCard(props: { offer: Offer; onViewInvitation?(): void }) {
  const featureFlagMrvCard = useFeatureWithUserStrategies(FEATURE_FLAG_ENABLED_MRV_CARD);

  return (
    <div className="overflow-hidden w-full bg-white-dark text-black font-normal font-sans text-md rounded-lg flex flex-col lg:flex-row">
      <div
        className={clsx(
          'h-60 lg:h-auto w-full bg-no-repeat bg-center lg:order-2 lg:w-6/12',
          featureFlagMrvCard.isEnabled ? 'bg-mrv-card' : 'bg-cards',
        )}
      />
      <div className="flex-1 bg-white flex-col rounded-lg w-full lg:w-6/12 shadow-lg">
        <div className="bg-blue mt-6 py-2 px-6 text-white font-medium justify-center rounded-r-lg inline-flex gap-3">
          <div className="bg-purple flex items-center justify-center w-5 h-5 rounded-full self-center">
            <svg className="m-auto" width="19" height="15" viewBox="0 0 16 15" fill="none">
              <path
                d="M8 0L9.79611 5.52786H15.6085L10.9062 8.94427L12.7023 14.4721L8 11.0557L3.29772 14.4721L5.09383 8.94427L0.391548 5.52786H6.20389L8 0Z"
                fill="white"
              />
            </svg>
          </div>
          <p className="self-center">You're eligible!</p>
        </div>

        <div className="flex flex-col p-6 gap-3">
          <div className="flex flex-col gap-2">
            <h4 className="font-medium text-5xl font-serif">Congratulations!</h4>
            <p className="text-lg">
              You’re eligible for the following HMBradley Credit Card invitation.<sup>1</sup>
            </p>
          </div>

          <div className="flex flex-wrap gap-y-3">
            <CreditLimit offer={props.offer} />
            {props.offer.promo && <FixedIntroRate promo={props.offer.promo} />}
            <Rate offer={props.offer} />
          </div>

          <Button
            type="fill"
            color="purple"
            className="self-start text-sm"
            toNext={routeFor(ACCOUNT_ONE_CLICK_CREDIT_OFFERS_OFFER_ID_PATH, {
              path: { offerId: props.offer.offerID },
            })}
            onClick={props.onViewInvitation}
          >
            View invitation
          </Button>

          <p className="text-base">
            *APR means annual percentage rate. This invitation expires{' '}
            {UTCDateLongFormatter.format(new Date(props.offer.expiresOn))}
          </p>
        </div>
      </div>
    </div>
  );
}

export function CreditOffers(props: { className?: string; onViewInvitation?(): void }) {
  const controller = useCreditOffer();

  if (controller.isLoading) {
    return null;
  }

  if (controller.isError) {
    return null;
  }

  if (!controller.creditCardOffers?.length) {
    return null;
  }

  return (
    <div className={clsx('flex flex-col gap-6 w-full', props.className)}>
      {controller.creditCardOffers.map((offer) => (
        <OfferCard onViewInvitation={props.onViewInvitation} key={offer.offerID} offer={offer} />
      ))}
    </div>
  );
}
