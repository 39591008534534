import { PATH_DEPOSIT_APPLICATION_JOINT_INVITE } from '@/constants/routes';
import { PlusIcon } from '@heroicons/react/24/outline';
import Link from 'next/link';
import React from 'react';

export function NewJointAccountLink() {
  return (
    <Link
      href={{
        pathname: PATH_DEPOSIT_APPLICATION_JOINT_INVITE,
      }}
      className="flex items-center justify-center border-gray-200 border-dashed border-2 rounded-lg col-span-12 lg:col-span-4"
    >
      <div className="flex items-center justify-center py-32">
        <PlusIcon className="text-gray-400 w-6 mr-1" />
        <p className="text-base font-medium text-gray-400">Joint Deposit Account</p>
      </div>
    </Link>
  );
}
